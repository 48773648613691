import Footer from 'components/Footer/Footer'
import Navbar from 'components/Header/Navbar'
import React from 'react'
import { Outlet } from 'react-router-dom'
import ScrollToTop from 'utils/ScrollToTop'

const Layout = ({ children }) => {
  return (
    <div className='pt-5 sm:pt-8 min-h-screen flex flex-col justify-between'>
      <ScrollToTop />
      <div className='max-w-7xl mx-auto px-3 sm:px-6 w-full'>
        <Navbar />
        <main className='mb-6 mt-12 sm:mb-12 sm:mt-24 w-full'>
          <Outlet />
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
