import { XIcon } from '@heroicons/react/outline'
import MyNavLink from 'utils/MyNavLink'

function Menu({ isOpen, setIsOpen }) {
  return (
    <div
      className={`overflow-hidden sm:hidden w-full h-full fixed inset-0 bg-white p-3 flex flex-col items-center justify-center transition-all duration-1000 ease-in-out z-10 ${
        isOpen ? 'left-0' : 'left-full'
      }`}
    >
      <div className='flex flex-col text-center text-base mini:text-lg gap-3 text-black'>
        <MyNavLink to='/' setIsOpen={setIsOpen}>
          <span>Home</span>
        </MyNavLink>
        <MyNavLink to='kinderophthalmologie' setIsOpen={setIsOpen}>
          Kinderophthalmologie
        </MyNavLink>
        <MyNavLink to='brillenbestimmung' setIsOpen={setIsOpen}>
          Brillenbestimmung
        </MyNavLink>
        <MyNavLink to='trockenes_auge' setIsOpen={setIsOpen}>
          Trockenes Auge
        </MyNavLink>
        <MyNavLink to='aesthetik_lidchirurgie' setIsOpen={setIsOpen}>
          Lidchirurgie/Ästhetik
        </MyNavLink>
        <MyNavLink to='laser' setIsOpen={setIsOpen}>
          Laser
        </MyNavLink>
        <MyNavLink to='ueber_mich' setIsOpen={setIsOpen}>
          Über mich
        </MyNavLink>
        <MyNavLink exact='true' to='kontakt' setIsOpen={setIsOpen}>
          Kontakt
        </MyNavLink>
      </div>
      <div
        className='absolute right-5 top-5 flex justify-center items-center font-normal text-gray-600'
        onClick={() => setIsOpen(false)}
      >
        <XIcon className='w-8 h-8' />
      </div>
    </div>
  )
}

export default Menu
