import Page from 'components/Page'
import React from 'react'
import {
  AcademicCapIcon,
  BriefcaseIcon,
  TranslateIcon,
  TrendingUpIcon,
  ChatIcon,
  EyeIcon,
} from '@heroicons/react/outline'
import { StarIcon, BeakerIcon, DownloadIcon } from '@heroicons/react/solid'

function UeberMich() {
  return (
    <Page title='Über mich'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <div className='flex flex-col gap-10 md:gap-0 md:block'>
          <div className='md:max-w-[320px] lg:max-h-[448px] text-right md:ml-8 md:mb-8 float-right'>
            <img
              src='images/kaya.jpg'
              alt='Priv.-Doz. Dr. Semira Kaya-Reindl, PhD'
            />
          </div>
          <div className='mt-4 md:mt-10'>
            <p className='uppercase text-accent-darkblue text-2xl'>
              Berufliche Laufbahn
            </p>
            <ul className='text-black'>
              <li className='mt-7'>
                <div className='text-accent-blue text-xl mb-4'>
                  <div className='h-full float-left mr-2'>
                    <AcademicCapIcon className='w-6 h-6' />
                  </div>
                  <span>Ausbildung</span>
                </div>
                <p>
                  <b>Auguts 2015: </b>Facharztprüfung für Augenheilkunde und
                  Optometrie
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>Mai 2016: </b>Abschluss des PhD Studiums (n094) an der
                  Medizinischen Universität Wien
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>Juli 2017: </b>Habilitation an der Paracelsus Medizinischen
                  Privatuniversität Salzburg
                </p>
              </li>
              <li className='mt-7'>
                <div className='text-accent-blue text-xl mb-4'>
                  <div className='h-full float-left mr-2'>
                    <StarIcon className='w-6 h-6' />
                  </div>
                  <span>Auszeichnungen</span>
                </div>
                <p>
                  <b>Mai 2015: </b>Forschungsförderpreis der Österreichischen
                  Ophthalmologischen Gesellschaft “ÖOG Scientist Award”
                  Projekttitel: “Effect of hyaluronic acid on tear film
                  thickness as assessed with ultra-high resolution optical
                  coherence tomography”
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>Oktober 2015: </b>Forschungsförderpreis der Deutschen
                  Ophthalmologischen Gesellschaft “Dry Eye Research Prize”,
                  Ressort Augenoberflächenerkrankungen. Projekttitel: “Prävalenz
                  der Meibomdrüsendysfunktion bei Patienten mit PEX und POWG
                  Glaukom”
                </p>
              </li>
              <li className='mt-7'>
                <div className='text-accent-blue text-xl mb-4'>
                  <div className='h-full float-left mr-2'>
                    <EyeIcon className='w-6 h-6 mt-px sm:mt-0' />
                  </div>
                  <span>Projekte und Forschungsschwerpunkte</span>
                </div>
                <div>
                  <h3 className='font-semibold text-base sm:text-lg'>
                    Okuläre Blutflußmessungen
                  </h3>
                  <ul className='mt-2 ml-5 list-outside list-disc'>
                    <li>
                      bei Patienten mit Augenerkrankungen (Glaukom) und
                      systemischen Erkrankungen - Diabetes mellitus Typ I,
                      chronisch obstruktiven Lungenerkrankungen – COPD
                    </li>
                  </ul>
                </div>
                <div className='mt-4 sm:mt-2'>
                  <h3 className='font-semibold text-base sm:text-lg'>
                    AMD - Altersbedingte Makuladegeneration
                  </h3>
                  <ul className='mt-2 ml-5 list-outside list-disc'>
                    <li className='mt-2 md:mt-1.5'>
                      Evaluierung der Makulapigmentdichte,
                      Makulapigmentdichtemessungen bei Patienten mit trockener
                      AMD - vor und nach Lutein Supplementation
                    </li>
                    <li className='mt-2 md:mt-1.5'>
                      Risikofaktoren der trockenen altersbedingten
                      Makuladegeneration
                    </li>
                  </ul>
                </div>
                <div className='mt-4 sm:mt-2'>
                  <h3 className='font-semibold text-base sm:text-lg'>
                    Trockenes Auge – “Dry Eye Disease”
                  </h3>
                  <ul className='mt-2 ml-5 list-outside list-disc'>
                    <li className='mt-2 md:mt-1.5'>
                      Augenoberflächenerkrankung, Erkrankungen der Tränen,
                      Tränendrüse, Bindehaut
                    </li>
                    <li className='mt-2 md:mt-1.5'>
                      Einfluß von Glaukommedikation auf die Augenoberfläche
                    </li>
                    <li className='mt-2 md:mt-1.5'>
                      Effekt der Vitaminsupplementation bei Patienten mit
                      trockenem Auge Syndrom
                    </li>
                  </ul>
                </div>
              </li>
              <li className='mt-7'>
                <div className='text-accent-blue text-xl mb-4'>
                  <div className='h-full float-left mr-2'>
                    <BeakerIcon className='w-6 h-6' />
                  </div>
                  <span>Wissenschaftliche Funktionen</span>
                </div>
                <p>
                  <b>Seit 2014: </b>Advisory board member “ECOS G - European
                  Club of Ocular Surface & Glaucoma” Thea Parma, Frankreich
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>Seit 2015: </b>Gründungsmitglied des “Workshop Trockenes
                  Auge - 3-Länder-Tagung” (Deutschland-Österreich-Schweiz)
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>Seit 2015: </b>Member der Science Ophtha Academy –
                  Wissenschaftliche Nachwuchsförderung, Allergan, Schweiz
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>Seit 2016: </b>Advisory board member “Severe Dry Eye
                  Disease”, Santen, Japan
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>Seit 2016: </b>Member of European Ophthalmologists Board on
                  “Demodex” Thea Pharma, Frankreich
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>2013 - 2017: </b>Lehre im Rahmen des Tertials
                  Augenheilkunde, Medizinisches Curriculum, Okuloplastische
                  Chirurgie, Orbita Chirurgie, Bedside-Teaching
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>2015 - 2017: </b>Lehrveranstaltungsleitung an der
                  Fachhochschule Salzburg Studiengang Orthoptik:
                  Lehrveranstaltung: Ophthalmologie
                </p>
              </li>
              <li className='mt-7'>
                <div className='text-accent-blue text-xl mb-4'>
                  <div className='h-full float-left mr-2'>
                    <BriefcaseIcon className='w-6 h-6' />
                  </div>
                  <span>Berufliche Funktionen</span>
                </div>
                <p>
                  <b>Seit 2013: </b>Aufbau und Leitung des Spezialbereiches für
                  Benetzungsstörungen der Augenoberfläche “Sicca Ambulanz” an
                  der Univ.-Augenklinik Salzburg
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>Seit 2015: </b>Aufbau und Leitung des Spezialbereiches
                  “Ästhetische Augenheilkunde (Botulinumtoxin, Hyaluronsäure,
                  Fadenlifting) an der Univ.-Augenklinik Salzburg
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>Seit 2017: </b>Aufbau und Leitung des Spezialbereiches für
                  Benetzungsstörungen der Augenoberfläche “Sicca Ambulanz” am
                  Krankenhaus Hietzing
                </p>
              </li>
              <li className='mt-7'>
                <div className='text-accent-blue text-xl mb-4'>
                  <div className='h-full float-left mr-2'>
                    <TranslateIcon className='w-6 h-6' />
                  </div>
                  <span>Sprachkentnisse</span>
                </div>
                <p className='mt-2 md:mt-1.5'>Deutsch (Muttersprache)</p>
                <p className='mt-2 md:mt-1.5'>Türkisch (Muttersprache)</p>
                <p className='mt-2 md:mt-1.5'>Englisch</p>
              </li>
              <li className='mt-7'>
                <div className='text-accent-blue text-xl mb-4'>
                  <div className='h-full float-left mr-2'>
                    <ChatIcon className='w-6 h-6' />
                  </div>
                  <span>Medienpräsenz</span>
                </div>
                <ul className='ml-5 list-outside list-disc'>
                  <li>Fernsehbeitrag zum Thema “Trockenes Auge”</li>
                  <li className='mt-2 md:mt-1.5'>
                    Mehrere Artikel in Printmedien zum Thema “Trockenes Auge”:
                    Kronenzeitung, Salzburger Nachrichten, Medical Network, Die
                    Bundesländerin,…
                  </li>
                  <li className='mt-2 md:mt-1.5'>Wiener Augentag Rathaus</li>
                  <li className='mt-2 md:mt-1.5'>
                    Women`s Day, Novomatic Forum, Wien
                  </li>
                </ul>
              </li>
              <li className='mt-7'>
                <div className='text-accent-blue text-xl mb-4'>
                  <div className='h-full float-left mr-2'>
                    <TrendingUpIcon className='w-6 h-6' />
                  </div>
                  <span>Beruflicher Werdegang</span>
                </div>
                <p>
                  <b>März - Juni 2012: </b>Assistenzärztin an der
                  Universitätsklinik für Augenheilkunde und Optometrie,
                  MUW-Medizinische Universität Wien
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>2009 - 2013: </b>Assistenzärztin an der Universitätsklinik
                  für Klinische Pharmakologie, MUW
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>2013 - 2017: </b>Fachärztin und Assistenzärztin an der
                  Universitätsklinik für Augenheilkunde und Optometrie,
                  Paracelsus Medizinische Privatuniversität, Salzburg
                </p>
                <p className='mt-2 md:mt-1.5'>
                  <b>2017 - 2019: </b>Fachärztin an der Abteilung für
                  Augenheilkunde und Optometrie am Krankenhaus Hietzing
                </p>
              </li>
            </ul>
          </div>
          <div className='mt-4 md:mt-10'>
            <p className='uppercase text-accent-darkblue text-2xl'>
              Publikationen
            </p>
            {/* Publikation Karten */}
            {/* <p className='mt-2 text-black/75'>Zuletzt erschienen:</p> */}
            {/* <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-2'>
              <PublikationCard
                title='Trockene Augen, was tun?'
                veranstaltung='Women`s Healt Day 2018'
                art='Vortrag'
                ort='Wien'
              />
              <PublikationCard
                title='Lidrandhygiene: Von Blepharitis bis Demodex'
                veranstaltung='Thea Wochenende'
                art='Vortrag'
                ort='Laa an der Thaya'
                jahr='2018'
              />
              <PublikationCard
                title='The on-off relationship between Demodex and Dry-Eye'
                veranstaltung='EVER Annual Meeting'
                art='Vortrag'
                ort='Nizza, Frankreich'
                jahr='2017'
              />
              <PublikationCard
                title='Prävalenz der Meibomdrüsendysfunktion bei Patienten mit PEX und POWG Glaukom'
                veranstaltung='DOG 2015'
                art='Vortrag'
                ort='Berlin, Deutschland'
              />
            </div> */}
            <p className='mt-4 text-lg sm:text-base'>
              Hier finden Sie eine vollständige Liste meiner Publikationen:
            </p>
            <a
              href='/downloads/Publikationsliste.pdf'
              className='mt-2 sm:mt-1 flex items-center gap-2 text-lg sm:text-base text-black link'
              target='_blank'
            >
              <DownloadIcon className='w-5 h-5' />
              <span>Publikationsliste</span>
            </a>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default UeberMich
