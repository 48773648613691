export const getCookie = function (name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
}

export const createCookie = (name, value) => {
  const d = new Date()
  d.setTime(d.getTime() + 183 * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  // document.cookie = `${name}=${value};${expires};domain=www.augenarzt-kaya.at;path=/`
  document.cookie = `${name}=${value};${expires};path=/`
}

export const addLink = (url, rel, ...props) => {
  var link = document.createElement('link')
  link.href = url
  link.rel = rel
  document.getElementsByTagName('head')[0].appendChild(link)
}
