import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ChevronUpIcon, DownloadIcon } from '@heroicons/react/solid'
import { createCookie, getCookie } from 'utils/utils'
import CookiesModal from 'components/CookiesModal'

function Footer() {
  const [accepted, setAccepted] = useState(false)
  const [cookieSettings, setCookieSettings] = useState(false)

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const acceptCookies = (necessary = true, functional = false) => {
    createCookie('cookieInfoNecessary', necessary)
    createCookie('cookieInfoFunctional', functional)
    createCookie('cookieInfoConsent', 'accepted')
    setAccepted(true)
    window.location.reload(true)
  }

  useEffect(() => {
    let consent = getCookie('cookieInfoConsent')
    if (consent === 'accepted') {
      setAccepted(true)
    }
  }, [setAccepted])

  return (
    <div className='relative w-full bg-black/90 pb-3'>
      <div className='max-w-7xl xl:w-3/4 2xl:w-3/5 mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 px-5 md:px-10 pt-12 place-items-center items-start  text-white text-center lg:text-left gap-8 md:gap-4'>
        <div className='w-full'>
          <NavLink to='kontakt'>
            <h3 className='uppercase text-white text-lg font-bold link hover:text-accent-turkis'>
              Kontakt
            </h3>
          </NavLink>
          <div className='mt-4 md:mt-6 2xl:mt-6 text-sm'>
            <h4>
              <b>Augenarzt</b>
            </h4>
            <div className='flex flex-col gap-0.5 w-full mt-3'>
              <span>Priv.-Doz. Dr. Semira Kaya-Reindl, PhD</span>
              <span className='mt-3 font-medium'>Ordination</span>
              <p className=''>Kinder Augenzentrum "Augenspiel"</p>
              <span>Albertgasse 41 (Erdgeschoss)</span>
              <span>1080 Wien</span>
              <span className='mt-1.5'>
                <b>Telefon: </b>{' '}
                <a
                  href='tel:+4314086284'
                  className='link hover:text-accent-turkis'
                >
                  +43 1 408 62 84
                </a>
              </span>
              <span className=''>
                <b>Mobil: </b>{' '}
                <a
                  href='tel:+436506308872'
                  className='link hover:text-accent-turkis'
                >
                  +43 650 630 88 72
                </a>
              </span>
              <span className='mt-3 font-medium'>Ordination</span>
              <span>Rudolfinerhaus Privatklinik</span>
              <span>Billrothstraße 78</span>
              <span>1190 Wien</span>
              <span className='mt-1.5'>
                <b>Mobil: </b>{' '}
                <a
                  href='tel:+43 650 6308872'
                  className='link hover:text-accent-turkis'
                >
                  +43 650 630 88 72
                </a>
              </span>
              <span>
                <b>E-Mail: </b>
                <a
                  href='mailto:office@augenarzt-kaya.at'
                  className='link hover:text-accent-turkis'
                >
                  office@augenarzt-kaya.at
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className='w-full'>
          <h3 className='uppercase text-white text-lg font-bold w-full'>
            Publikationen
          </h3>
          <div className='mt-4 md:mt-6 2xl:mt-6 text-sm'>
            <ul>
              <a href='/downloads/Publikationsliste.pdf' target='_blank'>
                <li className='flex items-center gap-2 justify-center lg:justify-start link hover:text-accent-turkis'>
                  <DownloadIcon className='w-4 h-4 sm:w-5 sm:h-5' />
                  Vollständige Liste herunterladen
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div className='w-full'>
          <h3 className='uppercase text-white text-lg font-bold w-full'>
            <NavLink to='kontakt_sprechstunde'>
              <h3 className='uppercase text-white text-lg font-bold link hover:text-accent-turkis'>
                Videosprechstunde
              </h3>
            </NavLink>
          </h3>
          <div className='mt-4 md:mt-6 2xl:mt-6 text-sm'>
            <p>
              Konsultieren Sie mich virtuell - ortsunabhängig, bequem und
              einfach
            </p>
            <p className='mt-2'>
              <b>Sprechstunden:</b> Nach elektronischer oder telefonischer
              Vereinbarung
            </p>
          </div>
        </div>
        <div className='text-center lg:text-left md:col-span-3 w-full text-sm sm:text-base md:mt-6'>
          <div className='w-full flex flex-wrap h-full items-center justify-center'>
            <span className=''>
              <NavLink to='/' className='link hover:text-accent-turkis'>
                Home
              </NavLink>
            </span>
            <div className='hidden mini:block w-0 h-7 border-l mx-3 transform bg-white/50'></div>
            <span className='w-fit'>
              <a
                href='https://etermin.lobmaier.at/kaya-semira-01'
                onClick={this}
                className='link'
              >
                Termin vereinbaren
              </a>
            </span>
            <div className='w-0 h-7 border-l mx-3 transform bg-white/50'></div>
            <span className=''>
              <NavLink to='kontakt' className='link hover:text-accent-turkis'>
                Kontakt
              </NavLink>
            </span>
            <br></br>
            <div className='w-0 h-7 border-l mx-3 transform bg-white/50'></div>
            <span className=''>
              <NavLink to='impressum' className='link hover:text-accent-turkis'>
                Impressum
              </NavLink>
            </span>
            <div className='hidden mini:block w-0 h-7 border-l mx-3 transform bg-white/50'></div>
            <span className='basis-full mini:basis-auto'>
              <NavLink
                to='datenschutz'
                className='link hover:text-accent-turkis'
              >
                Datenschutz
              </NavLink>
            </span>
          </div>
        </div>
      </div>
      <div
        className='mx-auto w-10 h-10 mt-5 sm:mt-0 border border-white bg-white p-2 sm:absolute bottom-5 right-10 text-black rounded-full cursor-pointer transform scale-100 transition-transform duration-300 hover:scale-110 group'
        onClick={scrollTop}
      >
        <ChevronUpIcon className='group-hover:animate-bounce' />
      </div>
      <p className='w-full sm:w-4/5 mx-auto text-center text-white/75 mt-5 sm:mt-7 text-xs px-4'>
        Copyright 2022 © Augenärztin Priv.-Doz. Dr. Semira Kaya-Reindl, PhD |
        All Rights Reserved | Realized by{' '}
        <a
          href='https://www.nossal.at'
          target='_blank'
          className='link underline hover:text-accent-turkis'
          rel='nofollow noreferrer external'
        >
          Dominik Nossal
        </a>
      </p>
      {!accepted && (
        <form className='fixed bottom-0 w-full bg-black/90 text-white text-sm px-10 py-5 lg:py-9 lg:flex lg:gap-20 items-start'>
          <p className=''>
            Diese Seite verwendet Cookies. Diese Cookies sind zum einen
            Notwendige Cookies, das bedeutet sie werden benötigt, damit die
            Webseite ordnungsgemäß funktioniert. Außerdem werden Funktionale
            Cookies gespeichert, die dabei helfen die Nutzerfreundlichkeit zu
            verbessern.
          </p>
          <div className='flex gap-4'>
            <button
              type='button'
              onClick={() => setCookieSettings(true)}
              className='min-w-fit inline px-6 py-1.5 mt-4 lg:mt-0 rounded font-semibold ring-white hover:ring-1 transition-all duration-300'
            >
              Einstellungen
            </button>
            <button
              type='button'
              onClick={() => acceptCookies(true, true)}
              className='min-w-fit inline ring-1 ring-white px-6 py-1.5 mt-4 lg:mt-0 rounded hover:text-black font-semibold hover:bg-white transition-colors duration-300'
            >
              Alle Akzeptieren
            </button>
          </div>
        </form>
      )}
      {cookieSettings && (
        <CookiesModal
          cookieSettings={cookieSettings}
          setCookieSettings={setCookieSettings}
          setAccepted={setAccepted}
        />
      )}
    </div>
  )
}

export default Footer
