import React from 'react'

function TerminButton() {
  return (
    <a
      className='w-11/12 sm:w-fit sm:px-10 mx-auto bg-white py-1 mt-4 border border-accent-darkblue text-center text-xl flex gap-1 justify-center items-center font-normal text-accent-darkblue rounded hover:text-white hover:bg-accent-darkblue transition-colors duration-200'
      href='https://etermin.lobmaier.at/kaya-semira-01'
      onClick={this}
    >
      <span>Online Termin vereinbaren</span>
    </a>
  )
}

export default TerminButton
