import Menu from 'components/Header/Menu/Menu'
import React, { useState } from 'react'
import Logo from './Logo'
import { MenuIcon } from '@heroicons/react/outline'
import KachelNav from './KachelNav'

function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className='relative'>
      <Logo />
      <div
        className='w-11/12 mx-auto bg-black py-1 mt-4 sm:hidden text-center flex gap-1 justify-center items-center font-normal text-white rounded'
        onClick={() => setIsOpen(true)}
      >
        <MenuIcon className='w-6 h-6' />
        <span className='text-md'>Menu</span>
      </div>
      <KachelNav></KachelNav>
      <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  )
}

export default Navbar
