import Page from 'components/Page'
import React from 'react'
import { NavLink } from 'react-router-dom'

function ErrorPage() {
  return (
    <Page title='404 - Seite nicht gefunden'>
      <div className='w-full text-center'>
        <p className='text-lg mb-5'>
          Uppsss..., diese Seite existiert leider nicht.
        </p>
        <p className='text-9xl font-bold text-black/40 mb-5'>404</p>
        <p className='mb-24 font-semibold'>
          <NavLink to='/' className='link'>
            <span>Zurück zur Startseite</span>
          </NavLink>
        </p>
      </div>
    </Page>
  )
}

export default ErrorPage
