import CookiesModal from 'components/CookiesModal'
import Page from 'components/Page'
import React, { useState } from 'react'

function Datenschutz() {
  const [cookieSettings, setCookieSettings] = useState(false)
  return (
    <Page title='Datenschutz'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <p className='uppercase text-accent-darkblue text-xl mini:text-2xl'>
          Datenschutz
        </p>
        <div className='mt-6'>
          <p>
            Im Rahmen der Website verarbeiten wir personenbezogene Daten, die
            Sie uns selbst bekanntgeben bzw. die wir durch Ihre Nutzung unseres
            Onlineauftritts erhalten, wie folgt:
          </p>
          <div className='mt-6'>
            <p className='text-lg text-accent-blue '>
              Erfassung und Verarbeitung personenbezogener Daten
            </p>
            <p className='mt-3'>
              Ihre personenbezogenen Daten werden nur dann erhoben, genutzt oder
              weitergegeben, wenn Sie dazu einwilligen oder es im gesetzlichen
              Rahmen erlaubt ist.
            </p>
            <p className='mt-3'>
              Als personenbezogene Daten gelten sämtliche Informationen zu Ihrer
              Person, die dazu dienen, Sie als Person zu bestimmen.
              Beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.
            </p>
            <p className='mt-3'>
              Es ist aber keine Pflicht Angaben zu Ihrer Person zu machen, um
              diese Webseite zu besuchen.
            </p>
          </div>
        </div>
        <div className='mt-6'>
          <p className='text-lg text-accent-blue'>Cookies</p>
          <div className='mt-3'>
            <p>
              Unsere Website verwendet so genannte Cookies. Dabei handelt es
              sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem
              Endgerät abgelegt werden. Sie richten keinen Schaden an.
            </p>
            <p className='mt-3'>
              Wir nutzen Cookies dazu, um Ihren Besuch so nutzerfreundlich wie
              möglich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät
              gespeichert, bis Sie diese löschen.
            </p>
            <p className='mt-3'>
              Bei der Deaktivierung von Cookies kann die Funktionalität unserer
              Website eingeschränkt sein.
            </p>
            <p className='mt-3'>
              Sie können jederzeit{' '}
              <span
                className='text-accent-blue cursor-pointer'
                onClick={() => setCookieSettings(true)}
              >
                hier
              </span>{' '}
              die Cookies Einstellungen ändern.
            </p>
          </div>
        </div>
        <div className='mt-6'>
          <p className='text-lg text-accent-blue'>Google Maps</p>
          <div className='mt-3'>
            <p>
              Unsere Website verwendet Funktionen des Webkartendienstes „Google
              Maps“.
            </p>
            <p className='mt-3'>
              Im Zuge der Nutzung von Google Maps ist es notwendig Ihre
              IP-Adresse zu speichern und zu verarbeiten. Google überträgt in
              der Regel an einen Server in den USA und speichert die Daten dort.
              Die Verarbeitung geschieht durch den Diensteanbieter (oben
              genannt), der Betreiber dieser Homepage hat keinen Einfluss auf
              die Übertragung der Daten.
            </p>
            <p className='mt-3'>
              Die Datenverarbeitung erfolgt auf Basis der gesetzlichen
              Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit f
              (berechtigtes Interesse) der DSGVO. Die Nutzung von Google Maps
              erhöht die Auffindbarkeit der Orte, welche auf unserer Webseite
              bereitgestellt werden.
            </p>
            <p className='mt-3'>
              Weitere Informationen über den Umgang mit Nutzerdaten des
              Diensteanbieters „Google“ können Sie deren Datenschutzerklärung
              entnehmen:{' '}
              <a
                href='https://policies.google.com/privacy?hl=de'
                rel='nofollow noreferrer external'
                className='text-accent-blue'
                target='_blank'
              >
                https://policies.google.com/privacy?hl=de
              </a>
            </p>
          </div>
        </div>
        <div className='mt-6'>
          <p className='text-lg text-accent-blue'>Google Fonts</p>
          <div className='mt-3'>
            <p>Unsere Website verwendet Schriftarten von „Google Fonts“.</p>
            <p className='mt-3'>
              Beim Aufrufen dieser Webseite lädt Ihr Browser Schriftarten und
              speichert diese in den Cache. Da Sie, als Besucher der Webseite,
              Daten des Dienstanbieters empfangen kann Google unter Umständen
              Cookies auf Ihrem Rechner setzen oder analysieren.
            </p>
            <p className='mt-3'>
              Die Nutzung von „Google-Fonts“ dient der Optimierung unserer
              Dienstleistung und der einheitlichen Darstellung von Inhalten.
            </p>
            <p className='mt-3'>
              Weitere Informationen zu Google Fonts erhalten Sie unter folgendem
              Link:{' '}
              <a
                href='https://developers.google.com/fonts/faq'
                rel='nofollow noreferrer external'
                className='text-accent-blue'
                target='_blank'
              >
                https://developers.google.com/fonts/faq
              </a>
            </p>
            <p className='mt-3'>
              Weitere Informationen über den Umgang mit Nutzerdaten des
              Diensteanbieters „Google“ können Sie deren Datenschutzerklärung
              entnehmen:{' '}
              <a
                href='https://policies.google.com/privacy?hl=de'
                rel='nofollow noreferrer external'
                className='text-accent-blue'
                target='_blank'
              >
                https://policies.google.com/privacy?hl=de
              </a>
            </p>
          </div>
        </div>
        <div className='mt-6'>
          <p className='text-lg text-accent-blue'>Ihre Rechte</p>
          <div className='mt-3'>
            <p>
              Als Betroffener haben Sie das Recht aus Auskunft über die
              gespeicherten Daten gemäß Art 15 DSGVO, auf Berichtigung
              unzutreffender Daten gemäß Art 16 DSGVO, auf Löschung von Daten
              gemäß Art 17 DSGVO, auf Einschränkung der Verarbeitung von Daten
              gemäß Art 18 DSGVO, auf Datenübertragbarkeit gemäß Art 20 DSGVO
              sowie auf Widerspruch gegen die unzumutbare Datenverarbeitung
              gemäß Art 21 DSGVO.
            </p>
            <p className='mt-3'>
              Sollten Sie vermuten, dass im Zuge der Verarbeitung Ihrer Daten
              Verstöße gegen das Datenschutzrecht passiert sind, so haben Sie
              die Möglichkeit sich bei uns (
              <a href='mailto:office@augenarzt-kaya.at' className='link'>
                office@augenarzt-kaya.at
              </a>
              ) oder der Datenschutzbehörde (
              <a href='mailto:dsb@dsb.gv.at' className='link'>
                dsb@dsb.gv.at
              </a>
              ) zu beschweren.
            </p>
          </div>
        </div>
      </div>
      {cookieSettings && (
        <CookiesModal
          cookieSettings={cookieSettings}
          setCookieSettings={setCookieSettings}
        />
      )}
    </Page>
  )
}

export default Datenschutz
