import React from 'react'
import TerminButton from 'utils/TerminButton'

function Logo() {
  return (
    <div className='flex flex-col font-nunito text-center pt-0.5 tracking-tight'>
      <p className='uppercase font-medium text-2xl sm:text-4xl text-accent-blue'>
        <span className='whitespace-nowrap'>Priv.-Doz. Dr. </span>
        <br className='md:hidden' />
        <span className='mini:whitespace-nowrap -mt-1'>
          <span className=''>Semira Kaya-Reindl</span>,{' '}
          <span className='normal-case'>PhD</span>
        </span>
      </p>
      <div className='uppercase text-black'>
        <p className='text-md sm:text-2xl mt-0 sm:mt-2'>
          Fachärztin Für Augenheilkunde und Optometrie
        </p>
        <TerminButton />
        <div className='block'>
          <div className='flex flex-col justify-center gap-5 mt-5'>
            <div>
              <div className='mt-0.5 text-base'>
                <p className='text-accent-darkblue'>
                  Kinder Augenzentrum "Augenspiel"
                </p>
                <p>Albertgasse 41 (Barrierefrei - Erdgeschoss)</p>
              </div>
              <p className='mt-1 text-md sm:text-xl'>1080 Wien</p>
            </div>
            <div className='mt-0.5 text-base'>
              <p className='text-accent-darkblue'>
                Ordination im Rudolfinerhaus
              </p>
              <p>Billrothstraße 78</p>
              <p>1190 Wien</p>
            </div>
          </div>
        </div>
      </div>
      <div className='sm:hidden flex justify-between mt-5 mb-1'>
        <div
          className={`w-1/4 h-[1px] bg-gray-600/25 transition-all duration-500`}
        ></div>
        <div
          className={`w-1/4 h-[1px] bg-gray-600/25 transition-all duration-500`}
        ></div>
      </div>
    </div>
  )
}

export default Logo
