import React from 'react'
import { NavLink } from 'react-router-dom'

function Kachel({ to, src, alt }) {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <div
          className={`w-full aspect-video bg-slate-500 ring-accent-blue transition-all duration-300 transform ${
            isActive ? 'ring-2 scale-105' : 'hover:scale-105'
          }`}
        >
          <img src={src} alt={alt} />
        </div>
      )}
    </NavLink>
  )
}

export default Kachel
