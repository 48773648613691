import React from 'react'
import useDocumentTitle from 'utils/useDocumentTitle'

function Page({ title, children }) {
  const titlePrefix = 'Augenärztin Kaya | '
  useDocumentTitle(`${titlePrefix}${title}`)
  return <>{children}</>
}

export default Page
