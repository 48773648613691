import Page from 'components/Page'
import React from 'react'

function Aesthetik() {
  const scrollTo = (id, block = 'start') => {
    document.querySelector(id).scrollIntoView({ block, behavior: 'smooth' })
  }

  return (
    <Page title='Ästhetik & Lidchirurgie'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <div
          className='flex flex-col gap-10 md:gap-0 md:block pt-2.5 sm:pt-10'
          id='lidchirurgie'
        >
          <h4 className='uppercase text-accent-darkblue text-2xl font-medium'>
            Lidchirurgie
          </h4>
          <div className='mt-4 md:mt-10 text-black'>
            <div className='flex flex-col gap-6 md:gap-0 md:block'>
              <h4 className='uppercase text-accent-darkblue text-2xl md:mb-7'>
                Oberlidkorrektur - Blepharoplastik
              </h4>
              <div className='md:max-w-xs lg:max-w-sm text-right md:ml-8 md:mb-4 float-right'>
                <img src='images/oberlidkorr.jpg' alt='Oberlidkorrektur' />
              </div>
              <p className='md:mt-4'>
                Die Lidkorrektur erfolgt ambulant, in örtlicher Betäubung und
                bei Wunsch im Dämmerschlaf. Die Lidstraffung ist schmerzfrei.
                Binnen 7 Tagen werden die Fäden entfernt. Die augenärztliche
                Technik basiert auf einer speziellen Methodik der Lidchirurgie
                und beeinhaltet auch eine Straffung der Lidmuskulatur, die für
                die Nachhaltigkeit des Ergebnisses sehr wichtig ist. Hierin
                unterscheidet sich die von Lidchirurgen verwendete Technik
                wesentlich von der häufig angewandten reinen Hautentnahme durch
                ästhetische Chirurgen. Die Augenlidkorrektur und Lidstraffung
                gehören dabei zu meinen Schwerpunkten.
              </p>
            </div>
            <div className='clear-both'></div>
            <div className='mt-10 flex flex-col gap-6 md:gap-0 md:block'>
              <h4 className='uppercase text-accent-darkblue text-2xl md:mb-7'>
                Entropium
              </h4>
              <div className='md:max-w-xs lg:max-w-sm text-right md:ml-8 md:mb-4 float-right'>
                <img src='images/entropium.jpg' alt='Entropium' />
              </div>
              <p className='md:mt-4'>
                Das Entropium ist eine Fehlstellung des Augenlids, bei dem die
                Lidkante einwärts gedreht ist. Das beständige Reiben der Wimpern
                auf Bindehaut und Hornhaut verursacht ein Fremdkörpergefühl im
                Auge. Der chronische Reizzustand kann zur Sekundärkomplikation
                einer Hornhautentzündung führen, dem Ulcus corneae
                (Hornhautulcus).
              </p>
            </div>
            <div className='clear-both'></div>
            <div className='mt-10 flex flex-col gap-6 md:gap-0 md:block'>
              <h4 className='uppercase text-accent-darkblue text-2xl md:mb-7'>
                Ektropium
              </h4>
              <div className='md:max-w-xs lg:max-w-sm text-right md:ml-8 md:mb-4 float-right'>
                <img src='images/ektropium.jpg' alt='Ektropium' />
              </div>
              <p className='md:mt-4'>
                Das Ektropium ist eine Fehlstellung des Augenlids, bei dem die
                Lidkante auswärts gedreht ist. In der Regel ist das Unterlid
                betroffen. Durch die Fehlstellung tritt Tränenträufeln auf, bei
                dem die Tränen über den Lidrand träufeln, weil der natürliche
                Abfluss nicht mehr gegeben ist. Da das Auge vom Augenlid nicht
                mehr vollständig geschützt werden kann, führt das Ektropium zu
                Reizzuständen an Augenlidern und Bindehaut. Auch kann es zu
                Sekundärkomplikationen an der Augenoberfläche kommen.
              </p>
            </div>
            <div className='clear-both'></div>
            <div className='mt-10 flex flex-col gap-6 md:gap-0 md:block'>
              <h4 className='uppercase text-accent-darkblue text-2xl md:mb-7'>
                Ptosis
              </h4>
              <div className='md:max-w-xs lg:max-w-sm text-right md:ml-8 md:mb-4 float-right'>
                <img src='images/ptosis.jpg' alt='Ptosis' />
              </div>
              <p className='md:mt-4'>
                Botox und Hyaluronsäure sind wirkungsvolle Stoffe zur temporären
                Gesichtsverjüngung. Welche Substanz wir bei unseren Patienten
                verwenden, entscheiden wir individuell und auf Ihre Bedürfnisse
                angepasst. Während Botox vermehrt im oberen Gesichtsabschnitt
                zur Minderung von Stirn- und Augenfalten zur Anwendung kommt,
                ist Hyaluronsäure als Filler ein beliebtes Mittel zur
                Aufpolsterung von Falten im unteren Gesichts- und
                Dekolletébereich. Je nach Wunsch des Patienten kann ein
                kombiniertes Verfahren beider Substanzen verwendet werden.
              </p>
            </div>
            <div className='clear-both'></div>
            <div className='mt-10'>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                Erstberatung Lidkorrektur
              </h4>
              <p className='mt-4'>
                Sie möchten gerne wissen, ob eine Augenlidkorrektur in Ihrem
                Fall sinnvoll erscheint und welches Verfahren für Sie in Frage
                kommt? Gerne bieten ich Ihnen hierzu eine Beratung in der
                Lidsprechstunde in meiner Ordination an. Bei diesem Erstgespräch
                gehe ich gerne auf Ihre Wünsche ein und bespreche mit Ihnen die
                in Frage kommenden Korrekturverfahren, die zu erwartenden
                Ergebnisse, sowie die Heilungszeiten der gewünschten Behandlung.
              </p>
            </div>
          </div>
        </div>
        <div
          className='flex flex-col gap-10 md:gap-0 md:block pt-10'
          id='aesthetik'
        >
          <h4 className='uppercase text-accent-darkblue text-2xl font-medium'>
            Ästhetik
          </h4>
          <div className='md:max-w-xs lg:max-w-md text-right md:ml-8 md:mb-8 float-right'>
            <img src='images/aesthetik.jpg' alt='Ästthetik' />
          </div>
          <div className='mt-4 md:mt-10 text-black'>
            <div>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                Botox oder Hyaluron zur Faltenbenhandlung?
              </h4>
              <p className='mt-4'>
                Faltenbehandlung liegt mehr denn je im Trend, der Fülle an
                wirkungsvollen Verfahren sei Dank. Die bekanntesten und
                gleichzeitig am häufigsten verwendeten Behandlungsmethoden sind
                die Faltenunterspritzungen mit Botox und Hyaluronsäure. Viele
                Patienten wissen vor der Beratung mit ihrem Arzt noch gar nicht,
                mit welchem der beiden Stoffe sie eine Unterspritzung vornehmen
                lassen wollen, oder ob diese sich überhaupt für das gewünschte
                Ergebnis eignen. Also wo genau liegt der Unterschied? Und was
                hilft gegen unschöne Falten im Gesicht am besten – Botox oder
                Hyaluron?
              </p>
            </div>
            <div className='mt-10 md:mt-16 flex flex-col gap-6 md:gap-0 md:block'>
              <h4 className='uppercase text-accent-darkblue text-2xl md:mb-7'>
                Was ist Botox?
              </h4>
              <div className='md:max-w-xs lg:max-w-sm text-right md:mr-8 md:mb-4 float-left'>
                <img src='images/botox.jpg' alt='Botox' />
              </div>
              <p className='md:mt-4'>
                Wenige Monate bis Jahre nach einer Katarakt-Operation (Grauer
                Star) kann es erneut zu einer Trübung im Auge kommen. Nachdem
                die natürliche Linse bereits entfernt und durch eine künstliche
                Linse ersetzt wurde, kann der sogenannte Nachstar zu einer
                Trübung der Hinterseite des Kapselsacks führen, in dem sich die
                Kunstlinse befindet. Mit einem YAG-Laser kann die Trübung ohne
                Probleme schnell und schmerzfrei entfernt werden.
              </p>
            </div>
            <div className='clear-both'></div>
            <div className='mt-10 md:mt-16 flex flex-col gap-6 md:gap-0 md:block'>
              <h4 className='uppercase text-accent-darkblue text-2xl md:mb-7'>
                Was ist Hyaluronsäure?
              </h4>
              <div className='md:max-w-xs lg:max-w-sm text-right md:mr-8 md:mb-4 float-left'>
                <img src='images/hyaluron.jpg' alt='Hyaluronsäure' />
              </div>
              <p className='md:mt-4'>
                Hyaluronsäure, allgemein auch kurz Hyaluron genannt, ist eine
                natürliche, gelartige Substanz, die auch vom Körper bis zu einem
                bestimmten Grad selbst produziert wird. Sie übernimmt neben
                vielen Funktionen auch wichtige Aufgaben des Bindegewebes,
                beispielsweise ist sie dank ihrer Fähigkeit, große Mengen
                Wassers zu binden, für den Feuchtigkeitshaushalt und die
                Straffung der Haut zuständig. Da die körpereigene Produktion mit
                der Zeit nachlässt (dies beginnt bereits etwa ab dem 25.
                Lebensjahr), verliert die Haut auch nach und nach ihre
                natürliche Elastizität, es entstehen Falten. Mit einer
                Hyaluronbehandlung lässt sich dieser Prozess auf natürlichem
                Wege aufhalten. Die Hyaluronsäure wird direkt in die Falte
                injiziert und füllt das schlaffe Bindegewebe dort auf, in der
                Folge hebt sich dieses und glättet die Haut. Das Verfahren kommt
                in unterschiedlichen Bereichen des Gesichts zum Einsatz,
                beispielsweise zur Unterspritzung von Marionettenfalte und
                Nasolabialfalte, aber auch zur Modellierung der Lippen oder
                allgemeinen Volumenbehandlung. Dekolleté-Falten können ebenfalls
                mittels einer Hyaluron-Injektion behandelt werden.
              </p>
            </div>
            <div className='clear-both'></div>
            <div className='mt-10 md:mt-16 flex flex-col gap-6 md:gap-0 md:block'>
              <h4 className='uppercase text-accent-darkblue text-2xl md:mb-7'>
                Welcher Wirkstoff eignet sich besser zur Faltenbehandlung?
              </h4>
              <div className='md:max-w-xs lg:max-w-sm text-right md:mr-8 md:mb-4 float-left'>
                <img src='images/falten.jpg' alt='Faltenbehandlung' />
              </div>
              <p className='md:mt-4'>
                Botox und Hyaluronsäure sind wirkungsvolle Stoffe zur temporären
                Gesichtsverjüngung. Welche Substanz wir bei unseren Patienten
                verwenden, entscheiden wir individuell und auf Ihre Bedürfnisse
                angepasst. Während Botox vermehrt im oberen Gesichtsabschnitt
                zur Minderung von Stirn- und Augenfalten zur Anwendung kommt,
                ist Hyaluronsäure als Filler ein beliebtes Mittel zur
                Aufpolsterung von Falten im unteren Gesichts- und
                Dekolletébereich. Je nach Wunsch des Patienten kann ein
                kombiniertes Verfahren beider Substanzen verwendet werden.
              </p>
            </div>
            <div className='clear-both'></div>
            <div className='mt-10'>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                Die Gesichtsmuskeln machen Ihre Mimik aus - Erstberatung
              </h4>
              <p className='mt-4'>
                Bei jedem Gesichtsausdruck werden mehr als 20 Muskeln im Gesicht
                bewegt. Im Laufe der Jahre können sich somit kleinere
                Mimik-Fältchen einprägen, die zum individuellen Charakter Ihres
                Gesichts gehören. Einige dieser Fältchen können jedoch stören.
                Einmal dauerhaft ausgeprägt, können Sie einen „falschen“
                Gesichtsausdruck vermitteln. Am Häufigsten sind, gerade bei
                Menschen mit lebhafter Gesichtsmimik, sogenannte Krähenfüße
                (Lachfältchen), Lippenfältchen, Stirnfalten und Zornesfalten.
                Durch eine Faltenbehandlung können die entsprechenden Muskeln
                etwas „ausgebremst“ werden, ohne Ihre Funktion auszuschalten.
                „Refiller“ sind natürliche Substanzen, die von der Haut sehr gut
                angenommen werden und so manifeste Falten wieder auffüllen. Wir
                beraten Sie gerne, ob in Ihrem Falle eine Faltenunterspritzung
                Sinn macht und Ihren Vorstellungen entsprechen kann.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Aesthetik
