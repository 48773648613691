import Page from 'components/Page'
import React from 'react'

function Kinderophthalmologie() {
  return (
    <Page title='Kinderophthalmologie'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <h3 className='uppercase text-accent-darkblue text-2xl mb-10 font-medium'>
          Kinder
          <wbr />
          ophthalmologie (Kinder
          <wbr />
          augenheilkunde)
        </h3>
        <div className='flex flex-col gap-10 md:gap-0 md:block'>
          <div className='md:max-w-xs lg:max-w-md text-right md:ml-8 md:mb-8 float-right'>
            <img
              src='images/kinderophthalmologie.jpg'
              alt='Kinderophthalmologie'
            />
          </div>
          <div className='mt-4 md:mt-10 text-black'>
            <h5 className='text-accent-blue text-lg'>
              Besonderheiten der Untersuchung beim Kind
            </h5>
            <p className='mt-2'>
              Ein Kind beim Augenarzt ist nicht einfach ein kleiner Erwachsener.
              Es gibt viele Besonderheiten, die der Arzt bei der Untersuchung
              und Behandlung beachten muss.
            </p>
            <p className='mt-2'>
              Die Kinderophthalmologie beschäftigt sich mit den speziellen
              Bedürfnissen von Kindern bei der Augenuntersuchung und der
              Behandlung von kindlichen Augenerkrankungen.
            </p>
            <div className='mt-10'>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                Welche Erkrankungen der Augen sind im Kindesalter häufig?
              </h4>
              <p className='mt-4'>
                Das Spektrum der Erkrankungen, die bei Kindern an den Augen
                auftreten, unterscheidet sich von den typischen Erkrankungen der
                Erwachsenen. In der Kinderophthalmologie (Kinderaugenheilkunde)
                häufig sind folgende Problematiken:
              </p>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>Schielen</h5>
                <p className='mt-2'>
                  Beim Schielen liegt eine Abweichung der Augenstellung von der
                  Sehachse des anderen Auges vor. Schielen muss behandelt
                  werden, um eine dauerhafte Sehverschlechterung eines Auges zu
                  verhindern (Schwachsichtigkeit, Amblyopie).
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>
                  Amblyopie (Schwachsichtigkeit)
                </h5>
                <p className='mt-2'>
                  Die Amblyopie entsteht dann, wenn der Seheindruck eines Auges
                  vom Gehirn ausgeschaltet wird (z. B. beim Schielen) oder das
                  Sehen durch andere Faktoren (Anisometrophie) nicht ausreichend
                  erlernt wird.
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>Fehlsichtigkeit</h5>
                <p className='mt-2'>
                  Zu den Fehlsichtigkeiten gehören Kurzsichtigkeit,
                  Weitsichtigkeit sowie Stabsichtigkeit (Hornhautverkrümmung).
                  Fehlsichtigkeiten können durch eine Brille ausgeglichen
                  werden.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Kinderophthalmologie
