import React from 'react'
import Page from 'components/Page'

// flex flex-col gap-10

function Home() {
  return (
    <Page title='Home'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <div className='flex flex-col gap-10 md:gap-0 md:block'>
          <div className='md:max-w-xs lg:max-w-md text-right md:ml-8 md:mb-8 float-right'>
            <img src='images/vorsorge.jpg' alt='Vorsorge' />
          </div>
          <div>
            <p className='mt-4 md:mt-0 md:mr-10 text-black'>
              Haben Sie schon einmal versucht, sich bei völliger Dunkelheit
              zurechtzufinden? Dann wissen Sie, wie schwer es ist, mobil zu
              bleiben, wenn die Orientierung fehlt. Mit einer regelmäßigen
              Vorsorgeuntersuchung beim Augenarzt gehen Sie sicher, alles dafür
              zu tun, um Ihr Augenlicht bis ins hohe Alter hinein zu behalten.
              Lesen Sie hier, welche Augenvorsorgeuntersuchungen es gibt und
              wann diese empfohlen werden.
            </p>
          </div>
        </div>
        <div className='mt-10'>
          <h1 className='uppercase text-accent-darkblue text-2xl'>
            Warum zum Augenarzt? Auf Vorsorge setzen
          </h1>
          <p className='mt-4 text-black'>
            Die Augen sind eines der wichtigsten, aber auch eines der
            empfindlichsten Sinnesorgane des Menschen. Im Alltag müssen sie
            permanent funktionieren und werden kaum geschont. Diese
            Leistungsfähigkeit hat ihren Preis. Wenn es nicht schon vorher zur
            Fehlsichtigkeit kommt, zeigen sich etwa ab dem 40. Lebensjahr erste
            Alterserscheinungen. Die Linse verliert an Elastizität und
            Alterssichtigkeit (Presbyopie) ist die Folge. Außerdem steigt das
            Risiko gefährlicher Augenerkrankungen wie dem Grünen Star (Glaukom).
            Später erhöht sich darüber hinaus das Risiko einer altersbedingten
            Makuladegeneration (AMD), die genau wie das Glaukom die häufigste
            Ursache für eine Erblindung ist. Die Frage sollte also nicht sein,
            warum eine Vorsorgeuntersuchung beim Augenarzt wichtig ist, sondern
            vielmehr, warum man das Risiko eingehen sollte, auf eine solche zu
            verzichten.
          </p>
          <p className='mt-4 text-black'>
            Wer auch im hohen Alter noch über eine gute Sehkraft verfügen will,
            der sollte regelmäßig den Augenarzt zur Vorsorge aufsuchen. Denn nur
            wenn Augenkrankheiten frühzeitig erkannt werden, können sie gut
            behandelt werden. Hat die Krankheit sich bereits bemerkbar gemacht,
            sind die Schäden meist irreparabel. Gerade das Glaukom gehört zu den
            Erkrankungen, die sich schleichend entwickeln und häufig viel zu
            spät bemerkt werden. Aber auch Tumore, Grauer Star und
            Netzhautdefekte können bei der Vorsorge beim Augenarzt rechtzeitig
            erkannt und behandelt werden.
          </p>
        </div>
        <div className='mt-10'>
          <h1 className='uppercase text-accent-darkblue text-2xl'>
            Wann zur Vorsorge? Ihre Termine beim Augenarzt
          </h1>
          <p className='mt-4 text-black'>
            Schon im Kindesalter ist eine Vorsorgeuntersuchung beim Augenarzt
            sinnvoll. Denn es sind die ersten Lebensjahre, in denen die Weichen
            für ein gutes Sehvermögen gestellt werden. Liegen also bereits im
            Säuglingsalter Anzeichen für eine Fehlsichtigkeit (Amblyopie) oder
            für Schielen (Strabismus) vor, empfiehlt sich eine Untersuchung beim
            Augenarzt. Wer die regelmäßigen Untersuchungen wahrnimmt, die jedem
            Kind in den ersten Jahren zustehen, geht auf Nummer sicher, denn
            hierbei untersucht der Kinderarzt auch die Sehkraft des Kindes,
            sodass schnell auf Auffälligkeiten reagiert werden kann.
          </p>
          <ul className='text-black'>
            <li className='mt-5 ml-5 md:ml-10'>
              <span className='text-accent-blue text-lg'>
                Ab dem 40. Lebensjahr
              </span>
              <p className='mt-1'>
                eine jährliche Augenvorsorgeuntersuchung zur
                Glaukom-Früherkennung, um einen vermeidbaren Sehverlust
                verhindern zu können. Patienten, die bereits Betroffene in der
                Familie haben, wird diese Vorsorgeuntersuchung beim Augenarzt
                schon ab dem 30. Lebensjahr empfohlen.
              </p>
            </li>
            <li className='mt-5 ml-5 md:ml-10'>
              <span className='text-accent-blue text-lg'>
                Ab dem 60. Lebensjahr
              </span>
              <p className='mt-1'>
                eine regelmäßige AMD-Früherkennungsuntersuchung, um eine
                schleichende Sehverschlechterung zu verhindern.
              </p>
            </li>
            <li className='mt-5 ml-5 md:ml-10'>
              <span className='text-accent-blue text-lg'>
                Kurzsichtige Patienten ab 3 Dioptrien
              </span>
              <p className='mt-1'>
                eine jährliche Netzhautvorsorge-Untersuchung, um Netzhautdefekte
                zu detektieren.
              </p>
            </li>
            <li className='mt-5 ml-5 md:ml-10'>
              <span className='text-accent-blue text-lg'>
                Regelmäßigen Augenarzt-Check für Verkehrsteilnehmer
              </span>
              <p className='mt-1'>Ab dem 40. Lebensjahr</p>
            </li>
          </ul>
        </div>
        <div className='mt-10'>
          <h1 className='uppercase text-accent-darkblue text-2xl'>
            Was passiert bei der Augenvorsorge untersuchung?
          </h1>
          <p className='mt-4 text-black'>
            Neben der allgemeinen Kontrolle der Sehschärfe und der Farb- und
            Kontrastwahrnehmung kann der Augenarzt bei der Vorsorge nach
            Absprache mit dem Patienten folgende Untersuchungen durchführen:
          </p>
          <ul className='text-black'>
            <li className='mt-5 ml-5 md:ml-10'>
              <span className='text-accent-blue text-lg'>
                Bei der Früherkennung von Glaukomen
              </span>
              <p className='mt-1'>
                misst der Arzt den Augeninnendruck und untersucht den Zustand
                des Sehnervenkopfes mittels einer Augenspiegelung. Ein Glaukom
                entsteht, weil das Kammerwasser im Auge mit zunehmendem Alter
                schlechter abfließen kann, was zu einem erhöhten Druck auf den
                Sehnerv führt.
              </p>
            </li>
            <li className='mt-5 ml-5 md:ml-10'>
              <span className='text-accent-blue text-lg'>
                Um eine mögliche, altersbedingte Makuladegeneration
              </span>
              <p className='mt-1'>
                frühzeitig zu erkennen, untersucht der Arzt die Netzhaut mit
                einer Lupe. Darüber hinaus erläutert er dem Patienten den
                Amsler-Gitter-Test, der auch zur Selbstkontrolle angewendet
                werden kann. Bei dem Test geht es darum, die Funktion der
                Netzhautmitte (Makula) zu prüfen. Dafür hält sich der Patient in
                einem normalen Leseabstand die Abbildung eines Gitters mit einem
                Punkt in der Mitte vors Gesicht und schaut mit jeweils einem
                Auge darauf. Erscheinen die Gitterlinien verzerrt, unterbrochen
                oder verschwommen, kann das auf AMD hindeuten.
              </p>
            </li>
            <li className='mt-5 ml-5 md:ml-10'>
              <span className='text-accent-blue text-lg'>
                Was bei der Vorsorge untersuchung außerdem geschieht
              </span>
              <p className='mt-1'>
                hängt vom Patienten und seinen Beschwerden ab: Der Arzt vermisst
                zum Beispiel Hornhautdicke und Nervenfaserschicht, überprüft das
                Gesichtsfeld oder nimmt eine Spiegelung des Augenhintergrunds
                vor.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Page>
  )
}

export default Home
