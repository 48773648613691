import Page from 'components/Page'
import React from 'react'

function Brillenbestimmung() {
  return (
    <Page title='Brillenbestimmung'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <h3 className='uppercase text-accent-darkblue text-2xl mb-10 font-medium'>
          Brillenbestimmung
        </h3>
        <div className='flex flex-col gap-10 md:gap-0 md:block'>
          <div className='md:max-w-xs lg:max-w-md text-right md:ml-8 md:mb-8 float-right'>
            <img src='images/brillenbestimmung.jpg' alt='Brillenbestimmung' />
          </div>
          <div className='mt-4 md:mt-10 text-black'>
            <p>
              Ihre Augengesundheit liegt mir am Herzen – ganz besonders, weil
              unser Augenlicht nicht zu ersetzen ist. Um Ihr Sehvermögen so
              lange es geht zu erhalten und auch um im Falle einer Erkrankung
              rechtzeitig reagieren zu können, empfehle ich Ihnen, jährlich eine
              Augenvorsorgeuntersuchung vorzunehmen. Und dies am besten von der
              frühen Kindheit bis ins hohe Alter, denn das Sehvermögen ändert
              sich bei jedem Menschen im Laufe seines Lebens. Egal, in welchem
              Lebensabschnitt Sie sich befinden gilt: je früher ich auftretende
              Erkrankungen und Sehstörungen erkenne, desto besser ist Ihre
              Prognose. Das menschliche Auge ist ein sensibles Organ. Die
              häufigste Ursache für unscharfes Sehen ist das Vorliegen einer
              Fehlsichtigkeit, also einer Kurz-, Weit- und/oder einer
              Stabsichtigkeit, die mit einer Sehhilfe korrigiert werden kann. Um
              für Sie die beste Lösung zu finden, biete ich Ihnen in meiner
              Praxis einen Sehtest mit anschließender Brillenglasbestimmung.
              Reicht eine Brille nicht mehr aus, um die Sehstörung zu beheben
              oder wurde eine Erkrankung Ihrer Augen diagnostiziert, steht in
              manchen Fällen eine Operation an. In diesem Fall biete ich Ihnen
              eine Operations-Vor- und -Nachsorge. Nach einer sorgfältigen
              Anamnese, einer gründlichen Untersuchung und Diagnostik wird
              entschieden, welches Operationsverfahren für Sie möglich oder
              überhaupt erforderlich ist.
            </p>
          </div>
          <div className='mt-10'>
            <h1 className='uppercase text-accent-darkblue text-2xl'>
              Sehfehler und die Korrektur von Fehlsichtigkeiten
            </h1>
            <ul className='mt-4 ml-5 text-black list-disc list-outside'>
              <li>
                <h5 className='font-medium'>Kurzsichtigkeit (Myopie)</h5>
                <p className='mt-1'>
                  Wenn Sie an Kurzsichtigkeit (Myopie) leiden, können Sie zwar
                  Dinge in der Nähe problemlos erkennen, ein Objekt in der Ferne
                  erscheint Ihnen aber unscharf und verschwommen. In Österreich
                  ist etwa ein Viertel der Bevölkerung von Kurzsichtigkeit
                  betroffen. Ursache für diese Fehlsichtigkeit ist in den
                  meisten Fällen ein zu langes Auge und/oder eine zu hohe
                  Brechkraft der Hornhaut.
                </p>
              </li>
              <li className='mt-4'>
                <h5 className='font-medium'>Weitsichtigkeit (Hyperopie)</h5>
                <p className='mt-1'>
                  Bei Weitsichtigkeit (Hyperopie) handelt es sich um einen
                  Brechfehler des Auges, bei dem entweder das Auge zu kurz
                  und/oder die Brechkraft der Hornhaut zu gering ist. Je näher
                  sich ein Objekt am Auge befindet, desto verschwommener sieht
                  man es. Stark weitsichtige Menschen sehen weder in der Nähe
                  noch in der Ferne scharf. Von diesem Sehfehler ist ein Drittel
                  der österreichischen Bevölkerung mittleren Alters betroffen.
                </p>
              </li>
              <li className='mt-4'>
                <h5 className='font-medium'>Stabsichtigkeit (Astigmatismus)</h5>
                <p className='mt-1'>
                  Stabsichtigkeit (Astigmatismus) tritt auf, wenn die Krümmung
                  der Hornhaut oder (seltener) der Linse nicht gleichmäßig ist.
                  Ein punktförmiges Objekt wird durch diese Fehlkrümmung
                  verzerrt und erscheint als Strich oder Stab, wodurch diese Art
                  der Fehlsichtigkeit ihren Namen erhalten hat.
                </p>
              </li>
            </ul>
          </div>
          <div className='mt-10'>
            <h1 className='uppercase text-accent-darkblue text-2xl'>
              Diagnosetechnik / Technologie – Grundlage jeder Behandlung
            </h1>
            <ul className='mt-4 ml-5 text-black list-disc list-outside'>
              <li>
                <h5 className='font-medium'>
                  Gesichtsfeldmessung (Perimetrie)
                </h5>
                <p className='mt-1'>
                  Unter der Gesichtsfeldmessung versteht man den gesamten
                  Bereich, den Sie wahrnehmen ohne Augen oder Kopf zu bewegen.
                  Das Gesichtsfeld entspricht bestimmten Bereichen der Netzhaut:
                  Liegen Störungen der Netzhaut oder der weiterleitenden
                  Nervenfasern vor, kommt es zu Ausfällen in bestimmten Anteilen
                  des Gesichtsfeldes. Daher kann die Untersuchung des
                  Gesichtsfeldes wichtige Rückschlüsse über die
                  Funktionstüchtigkeit der Netzhaut, des Sehnerven und der
                  Sehbahn liefern. Aus Art und Lokalisation des Ausfalls können
                  Rückschlüsse auf die Ursache der Erkrankung gezogen werden.
                  Man unterscheidet prinzipiell zwischen der statischen und der
                  kinetischen Gesichtsfelduntersuchung. Bei der automatischen
                  statischen Gesichtsfelduntersuchung wird mit ortsfesten Reizen
                  wechselnder Leuchtdichte die topografische Verteilung der
                  Empfindlichkeit für Lichtunterschiede innerhalb des
                  Gesichtsfeldes geprüft. Mit der kinetischen Perimetrie wird
                  die topografische Verteilung der
                  Lichtunterschiedsempfindlichkeit mittels bewegter Messmarken
                  unterschiedlichen Reizwertes untersucht. Die Perimetrie kommt
                  hauptsächlich in der Glaukomvorsorge/Verlaufsbeobachtung, der
                  Abklärung neurologisch-augenärztlicher Krankheitsbilder, zur
                  Abklärung unklarer Sehstörungen und für gutachterliche Zwecke
                  zur Anwendung.
                </p>
              </li>
              <li className='mt-4'>
                <h5 className='font-medium'>OCT der Netzhaut</h5>
                <p className='mt-1'>
                  Mittels OCT können die einzelnen Netzhautschichten untersucht
                  und Veränderungen in der Netzhaut genau lokalisiert werden.
                  Die berührungslose OCT Untersuchung ist ein wichtiger Baustein
                  in der Diagnostik von Netzhauterkrankungen. Die Befunde der
                  OCT Untersuchung sind in vielen Fällen für die
                  Therapieentscheidung wegweisend. Über eine Messung der
                  Netzhautdicke kann der Therapieerfolg bei verschiedenen
                  Therapien wie beispielsweise nach Medikamenteninjektion in den
                  Glaskörperraum exakt verfolgt werden. Einen besonderen
                  Stellenwert hat die OCT bei der altersbedingten
                  Makuladegeneration (AMD), bei diabetischen
                  Netzhauterkrankungen, nach Gefäßverschlüssen im Bereich der
                  Netzhaut, bei Membranen auf der Netzhautmitte (epiretinale
                  Gliose) und Makulalöchern (Makulaforamen).
                </p>
              </li>
              <li className='mt-4'>
                <h5 className='font-medium'>OCT des Sehnerven</h5>
                <p className='mt-1'>
                  Bei der OCT des Sehnerven wird Ihr Sehnervenkopf mithilfe
                  eines computergesteuerten Lasers abgetastet und
                  dreidimensional analysiert. Diese Untersuchung wird zur
                  Früherkennung des grünen Stars (Glaukom) eingesetzt. Mit
                  dieser Untersuchungsmethode kann auch im Rahmen einer
                  Verlaufsbeobachtung der Therapieerfolg bei der Behandlung des
                  grünen Stars überwacht werden. Veränderungen des Sehnerven
                  können mit der OCT des Sehnerven früher und objektiver als mit
                  anderen Untersuchungsmethoden festgestellt werden.
                </p>
              </li>
              <li className='mt-4'>
                <h5 className='font-medium'>OCT der Hornhaut</h5>
                <p className='mt-1'>
                  Bei der optischen Cohärenztomographie (OCT) wird reflektiertes
                  Licht analysiert und in kodierte Streulichtaufnahmen
                  umgewandelt, um ultrahochauflösende Querschnittsaufnahmen,
                  ähnlich eines histologischen Schnittes, zu erhalten. Die
                  schnelle und berührungsfreie OCT lässt sich ausgezeichnet dazu
                  nutzen, insbesondere die Hornhaut zu untersuchen und
                  darzustellen. Sie ermöglicht eine mikroskopisch genaue
                  Abbildung Ihrer Hornhaut und eine exakte Vermessung der
                  einzelnen Schichten. Zu den Anwendungen gehört die
                  Untersuchung der Hornhaut im Querschnitt und die Bestimmung
                  der Hornhautdicke, die wichtige Parameter für die Beurteilung
                  von Hornhauterkrankungen, wie des Keratokonus, für die
                  Glaukomdiagnostik und vor allem für die refraktive
                  Hornhauchirurgie liefern. Die einfache und kontaktfreie
                  OCT-Dickenmessung der Hornhaut ist ein erheblicher Vorteil für
                  eine optimale Evaluation möglicher refraktiver
                  Korrekturverfahren. Das Dickenprofil zeigt interaktiv den
                  gesamten Querschnitt der Hornhaut an. Auch Distanzen innerhalb
                  der Kornea können so einfach ermittelt werden, ebenso die
                  Analyse des Hornhautquerschnitts nach einer LASIK Operation
                  zur Bestimmung der Flapdicke und des Restgewebes der Hornhaut.
                  Dies ist vor allem im Rahmen der Planung refraktiver
                  Nachbehandlungen von großer Bedeutung. Mittels Hornhaut OCT
                  ist auch eine exakte Vermessung der Vorderfläche, der
                  Rückfläche und auch der Gesamtbrechkraft der Hornhaut möglich
                  – wichtig für die Planung und das klinische Ergebnis torischer
                  Intraokularlinsen im Rahmen einer Linsenchirurgie.
                </p>
              </li>
              <li className='mt-4'>
                <h5 className='font-medium'>OCT der Linse</h5>
                <p className='mt-1'>
                  Die Geometrie und Dickenmessung der eigenen Linse bietet in
                  einigen Fällen wertvolle Hinweise für die Risikobewertung
                  hinsichtlich des Auftretens eines akuten Glaukomanfalls bei
                  flacher Augenvorderkammer bzw. kleinen anatomischen
                  Gegebenheiten des vorderen Augenabschnittes.
                </p>
              </li>
              <li className='mt-4'>
                <h5 className='font-medium'>Optische Biometrie des Auges</h5>
                <p className='mt-1'>
                  Bei der Operation des Grauen Stars wird die trübe körpereigene
                  Augenlinse durch eine klare Kunstlinse ersetzt. Um abschätzen
                  zu können, welche Stärke die neue künstliche Linse haben muss,
                  benötigt man eine präzise Messung der Hornhautverkrümmung und
                  der Augenlänge. Je genauer die Messung, desto besser sind die
                  Voraussetzungen für die optimale Abschätzung der
                  Kunstlinsenstärke und desto sicherer kann die angestrebte
                  Brechkraft, die wir mit Ihnen im persönlichen
                  Aufklärungsgespräch festlegen, erreicht werden. Im Gegensatz
                  zum älteren Ultraschallverfahren hat die optische Biometrie
                  zudem den Vorteil, dass sie schnell und berührungslos ist
                  sowie entlang der Sehachse misst. Bei sehr fortgeschrittenen
                  Formen von Linsentrübungen funktioniert die Methode jedoch
                  nicht, hier kommen Ultraschallverfahren zur Bestimmung der
                  Augenlänge zur Anwendung, die Ich Ihnen in der Ordination
                  anbieten kann.
                </p>
              </li>
              <li className='mt-4'>
                <h5 className='font-medium'>Pachymetrie der Hornhaut</h5>
                <p className='mt-1'>
                  Die Werte für die zentrale Hornhautdicke sind von Mensch zu
                  Mensch unterschiedlich und schwanken zwischen etwa 450 µm und
                  650 µm. Die Dickenmessung Ihrer Hornhaut kann entweder mittels
                  Ultraschall oder mittels Licht erfolgen. Mit Licht kann ein
                  Dickenprofil über die gesamte Hornhautfläche erstellt werden.
                  Aus diesen Bildern wird mit einer Software die Dicke an sehr
                  vielen Einzelpunkten errechnet und ein Dickenprofil mit hoher
                  Genauigkeit rekonstruiert.
                </p>
                <p className='mt-2'>
                  Die zentrale Hornhautdicke spielt insbesondere im Rahmen
                  zweier Fragestellungen eine Rolle. Zum einen in der
                  Glaukomdiagnostik und zum anderen in der refraktiven
                  Hornhautlaserchirurgie. So ist die Messung des
                  Augeninnendrucks mit den üblichen Messverfahren abhängig von
                  biomechanischen Eigenschaften der Hornhaut, bei der die
                  Hornhautdicke eine Rolle spielt. Überdurchschnittlich dicke
                  Hornhäute verfälschen den Druckwert zu falsch höheren Werten.
                  Unterdurchschnittlich dünne Hornhäute verursachen eine
                  entgegengesetzte Fehlbestimmung, d.h. verfälschen den
                  Druckwert zu falsch niedrigeren Werten, was noch
                  problematischer ist.
                </p>
                <p className='mt-2'>
                  Für die Beurteilung, ob im individuellen Fall eine
                  Laserkorrektur einer Fehlsichtigkeit möglich ist, ist die
                  Pachymetrie sehr wichtig. Da bei refraktiven Laserbehandlung
                  mit dem Laser Hornhautgewebe abgetragen wird, muss
                  sichergestellt sein, dass die Hornhaut nach der Korrektur noch
                  dick genug ist, um auf Dauer mechanisch stabil zu bleiben.
                </p>
              </li>
              <li className='mt-4'>
                <h5 className='font-medium'> Ultraschall</h5>
                <p className='mt-1'>
                  Die Ultraschalluntersuchung spielt trotz hochentwickelter
                  bildgebender Verfahren wie der optischen Cohärenztomographie
                  in der augenärztlichen Diagnostik weiterhin eine wichtige
                  Rolle. Die Vorteile der Ultaschalluntersuchung liegen unter
                  anderem in der schnellen und unkomplizierten Durchführbarkeit.
                </p>
                <p className='mt-2'>
                  Die Ultraschalldiagnostik gliedert sich in verschiedene
                  Untersuchungsverfahren die jeweils bestimmte Augenabschnitte
                  darstellen können. Untersucht und dargestellt werden die
                  vorderen Augenabschnitte inklusive Hornhaut,
                  Augenvorderkammer, Kammerwinkel; Regenbogenhaut und der
                  Augenlinse. Die Untersuchung des hinteren Augenabschnittes
                  berücksichtigt den Glaskörper, die Netzhaut, die Aderhaut und
                  die Augenwand. Ein weiteres Augenmerk wird auf die Augenhöhle
                  mit ihren Muskeln, Gefäßen und dem Sehnerv gelegt. Beispiele
                  für Erkrankungen, bei denen eine Ultraschalluntersuchung eine
                  Rolle spielt, sind:
                </p>
                <ul className='mt-4 ml-8 list-circle list-outside'>
                  <li>
                    <h6 className='font-medium'>
                      Dichte Hornhauttrübung, ein dichter grauer Star oder
                      Glaskörpertrübungen bzw. -blutungen
                    </h6>
                    <p>
                      Mit Hilfe des Ultraschalls können die Netzhaut und die
                      hinteren Strukturen des Auges beurteilt werden.
                    </p>
                  </li>
                  <li className='mt-1'>
                    <h6 className='font-medium'>Eine Netzhautablösung</h6>
                    <p>
                      Die Ultraschalluntersuchung gibt Auskunft über die
                      Beschaffenheit einer Netzhautablösung und grenzt diese
                      gegen ähnlich aussehende Krankheitsbilder ab.
                    </p>
                  </li>
                  <li className='mt-1'>
                    <h6 className='font-medium'>Fremdkörper im Augeninneren</h6>
                    <p>
                      Der Ultraschall erlaubt häufig eine Darstellung und
                      Lokalisation des Fremdkörpers im Auge.
                    </p>
                  </li>
                  <li className='mt-1'>
                    <h6 className='font-medium'>
                      Raumforderungen im oder hinter dem Auge
                    </h6>
                    <p>
                      Die Ultraschalluntersuchung erlaubt die Messung der Größe
                      und gibt Aussagen über die Beschaffenheit des Tumors und
                      gibt dadurch wichtige Hinweise für die Planung des
                      weiteren Vorgehens.
                    </p>
                  </li>
                  <li className='mt-1'>
                    <h6 className='font-medium'>Sehnervenerkrankungen</h6>
                    <p>
                      Die Ultraschalluntersuchung erlaubt in einigen Fällen die
                      Lokalisation und Größenausdehnung eines krankhaften
                      Prozesses im Bereich des Sehnerven.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Brillenbestimmung
