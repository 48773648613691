import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './index.css'
import Layout from 'components/Layout/Layout'
import Kontakt from 'pages/Kontakt'
import Home from 'pages/Home'
import ErrorPage from 'pages/ErrorPage'
import UeberMich from 'pages/UeberMich'
import Impressum from 'pages/Impressum'
import Datenschutz from 'pages/Datenschutz'
import { addLink, getCookie } from 'utils/utils'
import Brillenbestimmung from 'pages/Brillenbestimmung'
import TrockenesAuge from 'pages/TrockenesAuge'
import Kinderophthalmologie from 'pages/Kinderophthalmologie'
import Laser from 'pages/Laser'
import Aesthetik from 'pages/Aesthetik'

const root = ReactDOM.createRoot(document.getElementById('root'))

const functional = getCookie('cookieInfoFunctional')
if (functional === 'true') {
  addLink('https://fonts.googleapis.com', 'preconnect')
  addLink('https://fonts.gstatic.com', 'preconnect', 'crossorigin')
  addLink(
    'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap',
    'stylesheet'
  )
  addLink(
    'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap',
    'stylesheet'
  )
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='brillenbestimmung' element={<Brillenbestimmung />} />
          <Route path='trockenes_auge' element={<TrockenesAuge />} />
          <Route
            path='kinderophthalmologie'
            element={<Kinderophthalmologie />}
          />
          <Route path='laser' element={<Laser />} />
          <Route path='aesthetik_lidchirurgie' element={<Aesthetik />} />
          <Route path='ueber_mich' element={<UeberMich />} />
          <Route path='kontakt' element={<Kontakt />} />
          <Route
            path='kontakt_sprechstunde'
            element={<Navigate to='/kontakt' replace={true} />}
          />
          <Route path='impressum' element={<Impressum />} />
          <Route path='datenschutz' element={<Datenschutz />} />
          <Route path='*' element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)
