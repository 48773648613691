import React from 'react'
import { NavLink } from 'react-router-dom'

function MyNavLink({ to, children, setIsOpen, ...props }) {
  const closeMenu = () => {
    setTimeout(() => setIsOpen(false), 250)
  }
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `${isActive ? 'text-lg mini:text-xl' : ''} transition-all duration-300`
      }
      {...props}
      onClick={closeMenu}
    >
      {({ isActive }) => (
        <div className='flex gap-2 items-center justify-center'>
          <div
            className={`${
              isActive ? 'w-5 mini:w-7' : 'w-0'
            } h-[1px] bg-gray-600/75 transition-all duration-500`}
          ></div>
          {children}
          <div
            className={`${
              isActive ? 'w-5 mini:w-7' : 'w-0'
            } h-[1px] bg-gray-600/75 transition-all duration-500`}
          ></div>
        </div>
      )}
    </NavLink>
  )
}

export default MyNavLink
