import React from 'react'
import Kachel from './Kachel'

function KachelNav() {
  return (
    <div className='mt-7 hidden sm:grid grid-cols-4 gap-4 2xl:gap-8 max-w-3xl 2xl:max-w-4xl mx-auto'>
      <Kachel to='/' src='images/kachel_images/home.jpg' alt='Home'></Kachel>
      <Kachel
        to='kinderophthalmologie'
        src='images/kachel_images/kinderophthalmologie.jpg'
        alt='Kinderophthalmologie'
      ></Kachel>
      <Kachel
        to='trockenes_auge'
        src='images/kachel_images/trockenes_auge.jpg'
        alt='Trockenes Auge'
      ></Kachel>
      <Kachel
        to='brillenbestimmung'
        src='images/kachel_images/brille.jpg'
        alt='Brillenbestimmung'
      ></Kachel>
      <div className='hidden'></div>
      <Kachel
        to='aesthetik_lidchirurgie'
        src='images/kachel_images/aestetische_chirurgie.jpg'
        alt='Ästhetik und Lidchirurgie'
      ></Kachel>
      <Kachel
        to='laser'
        src='images/kachel_images/laser.jpg'
        alt='Laser'
      ></Kachel>
      <Kachel
        to='ueber_mich'
        src='images/kachel_images/ueber_mich.jpg'
        alt='Über mich'
      ></Kachel>
      <Kachel
        to='kontakt'
        src='images/kachel_images/kontakt.jpg'
        alt='Kontakt'
      ></Kachel>
    </div>
  )
}

export default KachelNav
