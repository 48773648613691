import Page from 'components/Page'
import React from 'react'

function TrockenesAuge() {
  return (
    <Page title='Trockenes Auge'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <h3 className='uppercase text-accent-darkblue text-2xl mb-10 font-medium'>
          Trockenes Auge und Blepharitis
        </h3>
        <div className='flex flex-col gap-10 md:gap-0 md:block'>
          <div className='md:max-w-xs lg:max-w-md text-right md:ml-8 md:mb-8 float-right'>
            <img src='images/trockenes_auge.jpg' alt='Trockenes Auge' />
          </div>
          <div className='mt-4 md:mt-10 text-black'>
            <p>
              Trockene Augen haben bei über 80% als Hauptursache Blepharitis -
              eine chronische Lidrandentzündung. Sind die Lidranddrüsen
              chronisch entzündet, produzieren sie zu wenig Sekret. Dies führt
              zu einem instabilen Tränenfilm. Die Augen brennen, tränen und sind
              ständig gereizt. Trockene Augen sind unangenehm und die Behandlung
              der chronischen Blepharitis (Lidrandentzündung) ist nicht trivial.
              Oft stecken Krankheitserreger wie Bakterien und Hautmilben
              dahinter. Eine anhaltende und wirkungsvolle Therapie der Trockenen
              Augen muss den Befall der Lidranddrüsen durch Hautmilben und
              Bakterien bekämpfen und die Lidrandentzündung behandeln. Im Rahmen
              der Studien zur Intensive Licht-Puls-Therapie (auch IPL
              Lichttherapie genannt) konnte nachgewiesen werden, dass Trockene
              Augen und Blepharitis (Lidrandentzündung)-Behandlung durch die IPL
              Lichttherapie effektive und lang anhaltende Wirkung hat und
              Hautmilben und Bakterien erfolgreich bekämpft werden..
            </p>
            <div className='mt-10'>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                Symptome
              </h4>
              <div className='mt-4'>
                <h5 className='text-accent-blue text-lg'>Jucken</h5>
                <p className='mt-2'>
                  Trockene Augen jucken sehr oft. Das ist eine natürliche
                  Reaktion der Augen. Denn reibt man die Augen wegen des
                  Juckreizes, massiert man dabei natürlicherweise die
                  Lidrand-Drüsen – und diese geben dadurch wieder mehr
                  Flüssigkeit ab. Damit hilft sich das Auge quasi ein wenig
                  selbst.
                </p>
                <p>
                  Mit benetzenden Tropfen kann man zusätzlich unterstützen.
                  Halten die juckenden Augen trotz regelmäßiger und z. B. 4x
                  täglicher Gabe von unkonservierten Augentropfen weiter an,
                  kann auch eine allergische Ursache dahinter stecken.
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>Brennen</h5>
                <p className='mt-2'>
                  Trockene Augen jucken sehr oft. Das ist eine natürliche
                  Reaktion der Augen. Denn reibt man die Augen wegen des
                  Juckreizes, massiert man dabei natürlicherweise die
                  Lidrand-Drüsen – und diese geben dadurch wieder mehr
                  Flüssigkeit ab. Damit hilft sich das Auge quasi ein wenig
                  selbst
                </p>
                <p>
                  Mit benetzenden Tropfen kann man zusätzlich unterstützen.
                  Halten die juckenden Augen trotz regelmäßiger und z. B. 4x
                  täglicher Gabe von unkonservierten Augentropfen weiter an,
                  kann auch eine allergische Ursache dahinter stecken.
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>Tränen</h5>
                <p className='mt-2'>
                  Fehlt die oberste Schicht des Tränenfilms, die von den
                  Lidrand-Drüsen (Meibom-Drüsen) gebildet wird, ist der
                  Tränenfilm instabil. Die Folge ist ein Tränenlaufen, das
                  besonders bei Kälte, Wind oder auch schon beim Lachen
                  auftritt.
                </p>
                <p>
                  Diese Unterfunktion der Lidrand-Drüsen ist die Ursache bei
                  mehr als 80% der chronisch trockenen Augen. Diese
                  Lidrand-Entzündung (Blepharitis) ist eine chronische
                  Entzündung, die man gezielt behandeln kann.
                </p>
                <p className='mt-2'>
                  Zum einen steht als herkömmliches Verfahren die Lidrand-Pflege
                  zur Verfügung. Ergänzend und neu ermöglicht die
                  Lichtpuls-Therapie (IPL) als eine der erfolgreichsten
                  Therapien exzellente Resultate. Sie ist die weltweit einzige
                  Therapie, die an der Ursache ansetzt und Langzeitwirkung hat.
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>
                  Juckende Augenlider
                </h5>
                <p className='mt-2'>
                  Sie können auch Zeichen des trockenen Auges sein. Oft liegt
                  hier jedoch eine Allergie als Ursache vor, die als Folge der
                  allergischen Entzündung auch zu trockenen Augen führen kann.
                  Helfen können anti-allergische Tropfen oder eine Salbe, die
                  rezeptfrei in den Apotheken erhältlich sind.
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>
                  Juckender Augenlid-Rand
                </h5>
                <p className='mt-2'>
                  Sind die Beschwerden auf den Lidrand beschränkt, besteht eine
                  geminderte Funktion der wichtigen Lidrand-Drüsen
                  (Meibomdrüsen). Hier helfen die regelmäßig täglich
                  durchgeführte Lidrand-Pflege, unterstützt durch konsequent 4 x
                  täglich applizierte befeuchtende Augentropfen und als gute
                  Alternative die IPL mit exzellenten Ergebnissen.
                </p>
                <p className='mt-2'>
                  Auch ein Befall mit Milben (Demodex follicularum) kann Ursache
                  eines juckenden Lidrands sein und sollte augenärztlich
                  ausgeschlossen werden. Ursächlich können hier Behandlungen mit
                  Teebaumöl, Ivermectin-Salbe und die IPL zum Einsatz kommen und
                  das Problem ursächlich beheben.
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>
                  Druck- und Fremdkörpergefühl
                </h5>
                <p className='mt-2'>
                  Es liegt besonders bei Mangel des wässrigen Anteils des
                  Tränenfilms vor. Hier helfen in der Regel befeuchtende
                  Augentropfen. Sie sind rezeptfrei in den Apotheken zu
                  erhalten. Ich rate sie konsequent und regelmäßig 4x täglich
                  über mehrere Tage anzuwenden. Bleibt eine signifikante
                  Besserung aus, erbitte ich eine Vorstellung zur Beratung.
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>Wimpernausfall</h5>
                <p className='mt-2'>
                  Der Verlust von Wimpern ist ein Zeichen der chronischen und
                  dauerhaften Entzündung der Wimpern-Wurzeln. Vorausgehend
                  können wiederkehrende Rötungen der Lidränder sein, die mal
                  weniger und auch wieder ausgeprägter auftreten, und oft im
                  Rückblick auf Fotos bemerkt werden. Begleitet werden diese
                  Zeichen auch von Schmerzen und Schwellungen der Lidkanten.
                  Ursache hierfür sind in vielen Fällen Demodex-Milben.
                  Dauerhafte Abhilfe schaffen können Teebaumöl, Ivermectin und
                  die IPL.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default TrockenesAuge
