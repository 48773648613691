import Page from 'components/Page'
import React from 'react'

function Laser() {
  return (
    <Page title='Laser'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <h3 className='uppercase text-accent-darkblue text-2xl mb-10 font-medium'>
          Augenlasern
        </h3>
        <div className='flex flex-col gap-10 md:gap-0 md:block'>
          <div className='md:max-w-xs lg:max-w-md text-right md:ml-8 md:mb-8 float-right'>
            <img src='images/laser.jpg' alt='Augenlasern' />
          </div>
          <div className='mt-4 md:mt-10 text-black'>
            <div>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                YAG-Laser – Erklärung & Einsatzgebiete
              </h4>
              <p className='mt-4'>
                Beim YAG-Laser handelt es sich um einen Speziallaser, der in der
                refraktiven Chirurgie zur Behandlung des Nachstars in Folge
                einer Operation des Grauen Stars (Katarakt) verwendet wird oder
                zur Vorbeugung des Grünen Stars (Glaukom). YAG steht für
                „Yttrium-Aluminium-Granat“, es handelt sich um eine künstlich
                hergestellte Verbindung, die eine hohe akustische
                Wellengeschwindigkeit besitzt und für Festkörperlaser verwendet
                wird. Um eine gewünschte Wellenlänge zu emittieren, wird YAG mit
                verschiedenen Lanthanoiden dotiert, zum Beispiel Ytterbium,
                Neodym, Holmium oder Erbium.
              </p>
            </div>
            <div className='mt-10'>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                YAG-Laser bei Nachstar und Glaukomanfall
              </h4>
              <p className='mt-4'>
                Wenige Monate bis Jahre nach einer Katarakt-Operation (Grauer
                Star) kann es erneut zu einer Trübung im Auge kommen. Nachdem
                die natürliche Linse bereits entfernt und durch eine künstliche
                Linse ersetzt wurde, kann der sogenannte Nachstar zu einer
                Trübung der Hinterseite des Kapselsacks führen, in dem sich die
                Kunstlinse befindet. Mit einem YAG-Laser kann die Trübung ohne
                Probleme schnell und schmerzfrei entfernt werden.
              </p>
              <p className='mt-4'>
                Der YAG-Laser kann auch verwendet werden, wenn durch einen
                starken Innendruck des Auges ein Glaukomanfall droht. Dabei kann
                die Flüssigkeit im Augeninneren, das sogenannte Kammerwasser,
                nicht richtig abfließen, was zu starken Schmerzen und
                Schädigungen des Auges führen kann. Der Operateur kann den
                YAG-Laser nutzen, um eine kleine Öffnung in die Iris zu setzen,
                durch die das Kammerwasser besser der Zirkulation und des
                Wasserabflusses führen kann. Bei der ambulanten Behandlung mit
                dem YAG-Laser werden die Pupillen des Patienten mit Augentropfen
                erweitert, anschließend lenkt der Operateur mithilfe eines
                Kontaktglas den YAG-Laserstrahl ins Auge, wo die Trübung behoben
                wird.
              </p>
            </div>
            <div className='mt-10'>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                Laserbehandlung bei Diabetes
              </h4>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>
                  Was ist Diabetes und wie kann er die Augen schädigen?
                </h5>
                <p className='mt-2'>
                  Diabetes mellitus ist der medizinische Fachbegriff für die
                  sogenannte Zuckerkrankheit. Durch langfristig erhöhte
                  Zuckerwerte kommt es in verschiedenen Organen, Gefässen und
                  Nerven im Körper zu Schädigungen. Dadurch können
                  Nierenfunktionsstörungen, Missempfindungen und
                  Gefühlsstörungen in den Füssen oder auch Sehstörungen
                  auftreten. Solche Sehstörungen im Rahmen einer Zuckerkrankheit
                  entstehen in der Regel durch Schädigungen im Bereich der
                  Netzhaut. Bei einer sogenannten diabetischen Retinopathie
                  entstehen im Bereich der Netzhaut neue Gefässe, die weniger
                  dicht sind als andere Gefässe und daher schneller bluten
                  können. Dadurch kommt es zu Flüssigkeitsansammlungen und
                  anderen Ablagerungen in der Netzhaut. Auch eine Ablösung der
                  Netzhaut kann auftreten. Bei einer diabetischen Retinopathie
                  droht immer ein Fortschreiten der Erkrankung und
                  schlussendlich auch eine vollständige Erblindung. Um dies zu
                  verhindern ist es wichtig die Schädigung der Augen rechtzeitig
                  zu erkennen und eine Verschlechterung der Erkrankung zu
                  verhindern. Da sich die Erkrankung erst bei Komplikationen wie
                  einer Netzhautablösung (Sehen von Schleiern, Lichtblitzen oder
                  Russ-Regen) äussert, ist es insbesondere bei einer bekannten
                  Zuckerkrankheit wichtig, regelmässig augenärztliche
                  Untersuchungstermine wahrzunehmen.
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>
                  Welche Behandlungsmöglichkeiten gibt es?
                </h5>
                <p className='mt-2'>
                  Ziel einer Behandlung ist es das Wachstum neuer Gefässe zu
                  stoppen. Dabei stehen als therapeutische Optionen Injektionen
                  von wachstumshemmenden Medikamenten in der Glaskörper
                  (VEGF-Hemmer) oder eine Laserbehandlung zur Verfügung.
                </p>
              </div>
            </div>
            <div className='mt-10'>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                Welche Laserverfahren werden in der Behandlung einer
                diabetischen Retinopathie eingesetzt?
              </h4>
              <p className='mt-4'>
                Durch Laserverfahren können im Bereich der Gefässe Narben
                erzeugt werden, welche die undichten Gefässe verschliessen und
                die Bildung neuer Gefässe reduzieren können. Dabei kann
                unterschiedlich vorgegangen werden:
              </p>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>Photokoagulation</h5>
                <p className='mt-2'>
                  Bei der sogenannten Photokoagulation wird nach einer
                  vorgängigen Betäubung der Augen mit schmerzhemmenden Tropfen,
                  Licht bzw. Laserstrahlen appliziert und auf die Netzhaut
                  geleitet.
                </p>
              </div>
            </div>
            <div className='mt-10'>
              <h4 className='uppercase text-accent-darkblue text-2xl'>
                Welche Risiken und Komplikationen gibt es durch die
                Laserverfahren?
              </h4>
              <p className='mt-4'>
                Wie bei jedem Eingriff am Auge können verschiedenen
                Komplikationen auftreten, welche jedoch relativ selten sind. In
                einigen Fällen kann für eine bestimmte Zeit nach der
                Laserbehandlung schlechter bzw. etwas unschärfer, insbesondere
                in der Nacht, gesehen werden. Auch durch örtliche
                Betäubungsmittel können selten Komplikationen wie Allergien oder
                Verletzungen durch eine Spritze auftreten.
              </p>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>
                  Worauf muss nach der Behandlung geachtet werden?
                </h5>
                <p className='mt-2'>
                  Nach dem Eingriff sollten die Augen möglichst geschont werden.
                  Dazu gehört es Tätigkeiten wie Lesen, Autofahren oder das
                  Reiben der Augen zu vermeiden. Insbesondere wenn ein Verband
                  getragen wird, muss das Lenken eines Fahrzeugs unterlassen
                  werden. Zusätzlich sollten auch die Zuckerspiegel regelmässig
                  bestimmt und überwacht werden.
                </p>
              </div>
              <div className='mt-5'>
                <h5 className='text-accent-blue text-lg'>
                  Wie ist die Prognose nach einer Laserbehandlung bei Diabetes?
                </h5>
                <p className='mt-2'>
                  Eine genaue Prognose der Behandlung ist schwierig anzugeben,
                  da nicht alle Verläufe positiv beeinflusst werden können. In
                  den meisten Fällen kommt es jedoch zu einem Ansprechen auf die
                  Therapie. Wichtig zu erwähnen ist jedoch, dass in der Regel
                  mehrfache Behandlungen in regelmässigen Abständen notwendig
                  sind.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Laser
