import React, { useEffect, useState } from 'react'
import Page from 'components/Page'
import {
  AtSymbolIcon,
  PhoneIcon,
  ExclamationIcon,
} from '@heroicons/react/outline'
import { useSearchParams } from 'react-router-dom'
import { getCookie } from 'utils/utils'
import CookiesModal from 'components/CookiesModal'

function Kontakt() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [mailError, setMailError] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [cookieSettings, setCookieSettings] = useState(false)

  useEffect(() => {
    if (searchParams.get('status') === 'success') {
      searchParams.delete('status')
      setSearchParams(searchParams)
    } else if (searchParams.get('status') === 'error') {
      setMailError(true)
      scrollTo('#kontaktform')
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    const functional = getCookie('cookieInfoFunctional')
    if (functional === 'true') setShowMap(true)
  }, [setShowMap])

  const scrollTo = (id, block = 'start') => {
    document.querySelector(id).scrollIntoView({ block, behavior: 'smooth' })
  }

  return (
    <Page title='Kontakt'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <div className='flex flex-col-reverse xl:block gap-8'>
          <div className='xl:w-full xl:max-w-xl xl:float-right mt-0 xl:ml-10'>
            <h3 className='uppercase text-accent-darkblue text-xl mini:text-2xl flex items-center gap-2'>
              {/* <LocationMarkerIcon className='w-6 h-6 xl:w-6 xl:h-6' /> */}
              Lage
            </h3>
            {showMap && (
              <div>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2658.6938695674153!2d16.3416049!3d48.2125122!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d07e9a2acfecd%3A0x9ecc4ce8808f7d28!2sAlbertgasse%2041%2C%201080%20Wien!5e0!3m2!1sde!2sat!4v1655385315337!5m2!1sde!2sat'
                  title='Albertgasse 41'
                  height='300'
                  className='w-full mt-6'
                  allowfullscreen=''
                  loading='lazy'
                  referrerpolicy='no-referrer-when-downgrade'
                ></iframe>
                <iframe
                  className='w-full mt-6'
                  title='Rudolfinerhaus Privatklinik'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d664.2684446177437!2d16.34671802927016!3d48.243707191828975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d0628941ca599%3A0x5aa8bae416698f41!2sRudolfinerhaus%20Privatklinik%20GmbH!5e0!3m2!1sde!2sat!4v1654715779076!5m2!1sde!2sat'
                  height='300'
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              </div>
            )}
            {!showMap && (
              <div>
                <p>
                  Bitte aktivieren Sie die Funktionalen Cookies um die Google
                  Maps Karte zu sehen.{' '}
                  <span
                    className='text-accent-blue link cursor-pointer'
                    onClick={() => setCookieSettings(true)}
                  >
                    Einstellungen
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className=''>
            <div className='md:flex xl:block md:gap-5 lg:gap-10'>
              <div className='md:w-1/2 xl:w-auto'>
                <p className='uppercase text-accent-darkblue text-xl mini:text-2xl'>
                  Kontakt
                </p>
                <div>
                  <p className='font-semibold mt-6'>
                    Priv.-Doz. Dr. Semira Kaya-Reindl, PhD
                  </p>
                  <p className='mt-2'>Wahlärztin - Privatärztin</p>
                  <p className='mt-3 font-medium'>Ordination</p>
                  <p className=''>Kinder Augenzentrum "Augenspiel"</p>
                  <p>
                    <a
                      href='https://goo.gl/maps/Mo1ZqgoP9TWJBJbK9'
                      className='link'
                    >
                      Albertgasse 41 (Erdgeschoss)
                    </a>
                  </p>
                  <p>1080 Wien</p>
                  <div className='flex items-center gap-2 mt-2.5 group'>
                    <PhoneIcon className='w-5 h-5 text-black group-hover:text-accent-blue transition-colors duration-200' />
                    <div>
                      <div>
                        <span>Telefon: </span>
                        <a
                          href='tel:+43 650 6308872'
                          className='group-hover:text-accent-blue transition-colors duration-200'
                        >
                          +43 1 408 62 84
                        </a>
                      </div>
                      <div>
                        <span>Mobil: </span>
                        <a
                          href='tel:+43 650 6308872'
                          className='group-hover:text-accent-blue transition-colors duration-200'
                        >
                          +43 650 630 88 72
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center gap-2 mt-2 group'>
                    <AtSymbolIcon className='w-5 h-5 text-black group-hover:text-accent-blue transition-colors duration-200' />
                    <a
                      href='mailto:office@augenarzt-kaya.at'
                      className='group-hover:text-accent-blue transition-colors duration-200'
                    >
                      office@augenarzt-kaya.at
                    </a>
                  </div>
                  <p className='mt-5 font-medium'>Ordination</p>
                  <p className=''>Rudolfinerhaus Privatklinik</p>
                  <p>
                    <a
                      href='https://goo.gl/maps/WuByrz5XknYiM4qs9'
                      className='link'
                    >
                      Billrothstraße 78
                    </a>
                  </p>
                  <p>1190 Wien</p>
                  <div className='flex items-center gap-2 mt-5 group'>
                    <PhoneIcon className='w-5 h-5 text-black group-hover:text-accent-blue transition-colors duration-200' />
                    <a
                      href='tel:+43 650 6308872'
                      className='group-hover:text-accent-blue transition-colors duration-200'
                    >
                      +43 650 630 88 72
                    </a>
                  </div>
                  <div className='flex items-center gap-2 mt-2 group'>
                    <AtSymbolIcon className='w-5 h-5 text-black group-hover:text-accent-blue transition-colors duration-200' />
                    <a
                      href='mailto:office@augenarzt-kaya.at'
                      className='group-hover:text-accent-blue transition-colors duration-200'
                    >
                      office@augenarzt-kaya.at
                    </a>
                  </div>
                  <div className='flex gap-2 mt-4 group'>
                    <div className='w-5 h-5 flex-shrink-0 mt-0.5'>
                      <ExclamationIcon className='text-orange-500' />
                    </div>
                    <span className=''>
                      In Notfällen und am Wochenende kann ich Ihnen auch eine
                      kurzfristige Behandlung anbieten.
                    </span>
                  </div>
                </div>
              </div>
              <div className='mt-8 md:mt-0 xl:mt-8 md:w-1/2 xl:w-auto'>
                <p className='uppercase text-accent-darkblue text-xl mini:text-2xl'>
                  Videosprechstunde
                </p>
                <div>
                  <p className='mt-6'>
                    Konsultieren Sie mich virtuell - ortsunabhängig, bequem und
                    einfach.
                  </p>
                  {/* <p className='my-2'>
                    Jeden Montag von 16:00 Uhr bis 17:00 bin ich für Sie online
                    erreichbar.
                  </p> */}
                  <div className='flex items-center gap-2 mt-5 group'>
                    {/* <VideoCameraIcon className='w-5 h-5 text-black group-hover:text-accent-blue transition-colors duration-200' />
                    <a href='/' className='link'>
                      Sprechstunde beitreten...
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='mt-6'>
                <b>Jetzt ganz einfach und bequem einen Termin anfragen:</b>
                <ul className='list-outside ml-5 mt-2 list-disc'>
                  <li className='mt-1 ml-4 link w-fit'>
                    <a href='tel:+43 650 6308872'>Per Telefon</a>
                  </li>
                  <li className='mt-1 ml-4 link w-fit'>
                    <a href='mailto:office@augenarzt-kaya.at'>Per E-Mail</a>
                  </li>
                  <li className='mt-1 ml-4 cursor-pointer link w-fit'>
                    <span onClick={() => scrollTo('#kontaktform')}>
                      Über das Kontaktformular
                    </span>
                  </li>
                </ul>
              </div>
              <div className='mt-8 text-center' id='kontaktform'>
                <p className='uppercase text-accent-darkblue text-xl mini:text-2xl'>
                  Kontaktformular
                </p>
                <form
                  id='contaktform'
                  action='/scripts/sendMail.php'
                  method='post'
                  className='mx-auto max-w-3xl flex flex-col gap-5 mt-6'
                >
                  <div className='flex flex-col sm:flex-row w-full justify-between gap-6 sm:gap-16'>
                    <div className='w-full sm:w-1/2 flex flex-col items-start gap-2'>
                      <label htmlFor='prename' className='text-left'>
                        Vorname
                      </label>
                      <input
                        type='text'
                        name='prename'
                        id='prename'
                        className='w-full form-element'
                        required={true}
                      />
                    </div>
                    <div className='w-full sm:w-1/2 flex flex-col items-start gap-2'>
                      <label htmlFor='name'>Nachname</label>
                      <input
                        type='text'
                        name='name'
                        id='name'
                        className='w-full form-element'
                        required={true}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col sm:flex-row w-full justify-between gap-6 sm:gap-16'>
                    <div className='w-full sm:w-1/2 flex flex-col items-start gap-2'>
                      <label htmlFor='email' className='text-left'>
                        E-Mail-Addresse
                      </label>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        className='w-full form-element'
                        required={true}
                      />
                    </div>
                    <div className='w-full sm:w-1/2 flex flex-col items-start gap-2'>
                      <label htmlFor='phone'>Telefonnummer</label>
                      <input
                        type='tel'
                        name='phone'
                        id='phone'
                        className='w-full form-element'
                      />
                    </div>
                  </div>
                  <div className='w-full flex flex-col items-start gap-2'>
                    <label htmlFor='weekday' className='text-left'>
                      Bevorzugter Wochentag für einen Termin
                    </label>
                    <select
                      id='weekday'
                      name='weekday'
                      className='w-full form-element'
                      defaultValue='Keine Präferenz'
                    >
                      <option value='Keine Präferenz'>Kein Präferenz</option>
                      <option value='Montag'>Montag</option>
                      <option value='Dienstag'>Dienstag</option>
                      <option value='Mittwoch'>Mittwoch</option>
                      <option value='Donnerstag'>Donnerstag</option>
                      <option value='Freitag'>Freitag</option>
                    </select>
                  </div>
                  <div className='w-full flex flex-col items-start gap-2'>
                    <label htmlFor='phone'>Nachricht</label>
                    <textarea
                      name='message'
                      id='message'
                      className='w-full form-element resize-none'
                      rows='4'
                      required={true}
                    ></textarea>
                  </div>
                  {mailError && (
                    <p className='text-red-500'>
                      Es ist ein unerwarteter Fehler aufgetreten. Bitte
                      versuchen Sie es erneut.
                    </p>
                  )}
                  <button
                    type='submit'
                    className='ring-1 ring-accent-darkblue w-fit mx-auto py-2 px-5 rounded hover:bg-accent-darkblue hover:text-white transition-colors duration-300'
                  >
                    Absenden
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {cookieSettings && (
          <CookiesModal
            cookieSettings={cookieSettings}
            setCookieSettings={setCookieSettings}
          />
        )}
      </div>
    </Page>
  )
}

export default Kontakt
