import Page from 'components/Page'
import React from 'react'

function Impressum() {
  return (
    <Page title='Impressum'>
      <div className='w-11/12 mx-auto mb-10 md:mb-20'>
        <p className='uppercase text-accent-darkblue text-xl mini:text-2xl'>
          Impressum
        </p>
        <div className='mt-6'>
          <p className='font-semibold text-lg'>
            Privatdozentin Dr. Semira Kaya-Reindl, Phd
          </p>
          <div className='mt-4'>
            <p>Rudolfinerhaus Privatklinik</p>
            <p className='mt-1'>Billrothstraße 78</p>
            <p className='mt-1'>A - 1190 Wien</p>
          </div>
          <div className='mt-4'>
            <p>Telefon: +43 650 6308872</p>
            <p className='mt-1'>E-Mail: office@augenarzt-kaya.at</p>
          </div>
          <div className='mt-4'>
            <p>
              Berufsbezeichnung: Fachärztin für Augenheilkunde und Optometrie
            </p>
          </div>
        </div>
        <div>
          <p className='text-lg text-accent-blue mt-6'>Haftungsausschluss</p>
          <div className='mt-3'>
            <p>
              Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
              Haftung für die Inhalte externer Links. Für den Inhalt der
              verlinkten Seiten sind ausschließlich deren Betreiber
              verantwortlich. Sollten Ihnen dennoch Links auffallen, die auf
              rechtswidrige Webseiten verweisen, ersuchen wir um
              dementsprechenden Hinweis (
              <a href='mailto:office@augenarzt-kaya.at' className='link'>
                office@augenarzt-kaya.at
              </a>
              )
            </p>
            <p className='mt-3'>
              Ebenso sind trotz sorgfältiger Kontrollen auf dieser Seite Fehler
              und Irrtümer nicht auszuschließen. Wir übernehmen daher auch keine
              Gewähr für diese Informationen.
            </p>
          </div>
        </div>
        <div>
          <p className='text-lg text-accent-blue mt-6'>Urheberrechtshinweis</p>
          <div className='mt-3'>
            <p>
              Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
              unterliegen dem Urheberrecht. Falls notwendig, werden wir die
              unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
              verfolgen.
            </p>
          </div>
        </div>
        <div>
          <p className='text-lg text-accent-blue mt-6'>Bildernachweis</p>
          <div className='mt-3'>
            <p>
              Die Bilder, Fotos und Grafiken auf dieser Webseite sind
              urheberrechtlich geschützt.
            </p>
            <p className='mt-3'>
              Die Bilderrechte liegen bei den folgenden Fotografen und
              Unternehmen:
            </p>
            <ul className='list-disc list-outside mt-3 text-accent-darkblue'>
              <li className='ml-6'>Semira Kaya</li>
              <li className='ml-6 mt-2'>
                <a
                  href='https://www.pexels.com/'
                  rel='nofollow noreferrer external'
                  className='link'
                >
                  Pexels
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p className='text-lg text-accent-blue mt-6'>
            Technische Realisierung
          </p>
          <div className='mt-2'>
            <p>Dominik Nossal</p>
            <p>
              <a
                href='https://www.nossal.at'
                target='_blank'
                rel='nofollow noreferrer external'
                className='link text-accent-darkblue'
              >
                www.nossal.at
              </a>
            </p>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Impressum
